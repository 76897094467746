import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { ErrorHandler } from '@angular/core';
import { AppInsightsService } from './app-insights.service';

export const provideAppInsights = () => {
  return [
    AppInsightsService,
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService,
    },
  ];
};
