
export const environment = {
  production: true,
  birdeye_url:'https://api.birdeye.com',
  birdeye_business_id:'163295411066298',
  birdeye_api_key:'e38mpcbVN3AHx4QO6qgnzkHI9PcphWts',
  api_url:'https://api-use-d365ce-digital-pd.azurewebsites.net',
  google_api_key:'AIzaSyBEhwXWeI0RQUYw9mUSHmiq_wnr4rGif-c',
  app_insights_instrumentation_key:'9c3bbcdf-e595-480e-acc9-56b9641b447f',
};

export const Config = {
  api_key: 'blt48cc588eef0ffd43',
  delivery_token: 'csc90d0424f1ff53f8d4608571',
  environment: 'production',
  branch: 'main',
  region: 'us',
  preview_token:'csd038f6012d6ea5124822720f',
  preview_host:'eu-rest-preview.contentstack.com/azure-na-rest-preview.contentstack.com/azure-eu-rest-preview.contentstack.com',
  api_host:'azure-na-api.contentstack.com',
  app_host:'app.contentstack.com',
  live_preview:false
};
