import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';
import { ButtonComponent } from 'src/app/shared/components/button/button.component';

@Component({
  selector: 'app-not-found',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  templateUrl: './not-found.component.html',
  styleUrl: './not-found.component.scss',
})
export class NotFoundComponent {
  constructor(private navigationService: NavigationService) {}

  goToTheHomePage = () => {
    this.navigationService.navigateToPath('');
  };
}
