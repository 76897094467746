export const VALIDATION_PATTERNS = {
  email: /^[\w]{1,}[\w.+-]{0,}@[\w-]{1,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$/,
  text: /^(?!\\s*$)[a-zA-Z0-9-'`\‘\’\u2018\u2019 ]*$/
}

export const ERROR_MESSAGES = {
  FIELD_REQUIRED: 'Field is required.',
  INVALID_PHONE: 'Please enter a valid phone number',
  INVALID_ZIPCODE: 'Zipcode must be 5 digits.',
  INVALID_EMAIL: 'Invalid email.',
  FORBIDDEN_PHONE: 'This phone number is not allowed.',
  PHONE_PATTERN: 'Phone number must be 10 digits.',
  INVALID_CHARACTERS: 'Please make sure your input does not contain invalid characters.'
}