<app-main-layout>
  <div class="call-us">
    <div class="main-heading">
      <h2 [innerHtml]="pageContent[0]?.dce_page_introduction?.title"></h2>
      <div
        [innerHtml]="pageContent[0]?.dce_page_introduction?.text"
        class="cs-inner-html-16"
      ></div>
    </div>
    <div
      [innerHtml]="pageContent[1]?.dce_call_us?.text"
      class="cs-inner-html-16 center"
    ></div>
    <app-button
      [text]="pageContent[1]?.dce_call_us?.button?.text"
      type="filled"
      leftIconSrc="/assets/icons/phone-call-black.svg"
      [handleClick]="onButtonClick"
      [fullWidth]="true"
    />
  </div>
</app-main-layout>