import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { ContentstackQueryService } from 'src/app/services/cs.query.service';
import { ContentstackModule } from 'src/modules/contentstack/contentstack.module';
import { FormGroupComponent } from 'src/app/shared/components/form-group/form-group.component';
import { MainLayoutComponent } from 'src/app/shared/components/main-layout/main-layout.component';
import { DataService } from 'src/app/services/data.service';
import { TopicButtonComponent } from 'src/app/shared/components/topic-button/topic-button.component';
import { Observable, Subscription, map, of } from 'rxjs';
import {
  AdditionalComments,
  Topic,
  FormData,
} from 'src/app/services/data.model';
import { NAVIGATION_MAP, PAGES } from 'src/app/shared/constants/navigation-map';
import { NextButtonStateService } from 'src/app/services/next-button-state.service';
import { TimerComponent } from 'src/app/shared/components/timer/timer.component';

@Component({
  selector: 'app-additional-comments',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ContentstackModule,
    MainLayoutComponent,
    FormGroupComponent,
    TopicButtonComponent,
    TimerComponent,
  ],
  providers: [ContentstackQueryService],
  templateUrl: './additional-comments.component.html',
  styleUrl: './additional-comments.component.scss',
})
export class AdditionalCommentsComponent implements OnInit, OnDestroy {
  pageContent: any[] = [];
  additionalCommentsForm: FormGroup;
  topics: {
    title: string;
    topics_list: Topic[];
  };
  sub: Subscription;
  additionalCommentsFormSub: Subscription;
  previouslyFilledData: AdditionalComments = {
    comments: {
      comments: ''
    },
    topics: []
  };

  constructor(
    private contentstack: ContentstackQueryService,
    private fb: FormBuilder,
    private dataService: DataService,
    public nextButtonState: NextButtonStateService
  ) {
    this.getEntry();
  }

  ngOnInit(): void {
    this.buildForm();
  }

  getEntry() {
    this.contentstack
      .getEntryWithQuery(
        'dce_page',
        { key: 'url', value: '/additional-comments' },
        [],
        []
      )
      .then(
        (entry) => {
          this.pageContent = entry[0][0]?.page_components;
          this.topics = this.pageContent[1]?.dce_additional_comments?.topics;
          this.topics.topics_list = this.topics?.topics_list.sort(
            () => Math.random() - 0.5
          );
          if (this.dataService.id) {
            this.sub = this.dataService
              .getStepsData(this.dataService.id)
              .subscribe((data) => {
                this.prepopulateStepData(data);
              });
          }
        },
        (err) => {}
      );
  }

  buildForm() {
    this.additionalCommentsForm = this.fb.group({
      comments: ['', [Validators.maxLength(3000)]],
    });
    this.subscribeToFormChanges();
  }

  prepopulateStepData(data: FormData) {
    if (data?.appointmentRequest?.customerComments.length) {
      this.previouslyFilledData.comments.comments = data.appointmentRequest.customerComments;
      const sesssionData = JSON.parse(data.sessionData);
      this.previouslyFilledData.topics = sesssionData.topics;
      this.updateFormValues();
    }
    this.checkFormValidity();
  }

  subscribeToFormChanges(): void {
    this.additionalCommentsFormSub = this.additionalCommentsForm.valueChanges.subscribe((value) => {
      this.checkFormValidity();
    });
  }

  checkFormValidity() {
    setTimeout((_) => {
      this.nextButtonState.emitActivateNext(this.additionalCommentsForm.valid);
    });
  }

  onClickNext = (): Observable<any> => {
    if (this.additionalCommentsForm.valid) {
      return this.saveData(NAVIGATION_MAP[PAGES.ADDITIONAL_COMMENTS].nextButton.route);
    }
    this.additionalCommentsForm.markAllAsTouched();
    return of(false);
  };

  onClickBack = (): Observable<any> => {
    return this.additionalCommentsForm.valid ? this.saveData(NAVIGATION_MAP[PAGES.ADDITIONAL_COMMENTS].backButton.route) : of(true);
  };

  saveData(nextPage): Observable<any> {
    const stepsData = {...this.dataService.stepsData};

    stepsData.appointmentRequest.customerComments = this.additionalCommentsForm.value.comments;
    const sessionData = JSON.parse(stepsData.sessionData);
    sessionData.nextPage = nextPage;
    sessionData.topics = this.topics.topics_list.filter(
      (item: Topic) => item.selected
    )
    stepsData.sessionData = JSON.stringify(sessionData);
    return this.dataService
    .saveStepsData(stepsData)
    .pipe(
      map((res) => {
        return true;
      })
    );
  }

  updateFormValues() {
    this.additionalCommentsForm.patchValue({
      comments: this.previouslyFilledData.comments.comments,
    });
    this.topics.topics_list = this.topics.topics_list.map((topic: Topic) => {
      if (
        this.previouslyFilledData.topics.filter(
          (previouslySelected) =>
            topic.unique_name === previouslySelected.unique_name
        ).length > 0
      ) {
        return { ...topic, selected: true };
      } else {
        return topic;
      }
    });
  }

  onSelectTopic(item): void {
    const selectedItems = this.topics.topics_list.filter(
      (item) => item.selected
    ).length;

    this.topics.topics_list = this.topics.topics_list.map((topic) => {
      if (item.unique_name === topic.unique_name) {
        if (selectedItems === 3 && !item.selected) {
          return topic;
        }
        return { ...topic, selected: !item.selected };
      } else {
        return topic;
      }
    });
  }

  identify(index: number, item: { unique_name: string }): string {
    return item.unique_name;
  }

  ngOnDestroy(): void {
    if (this.sub) this.sub.unsubscribe();
    if (this.additionalCommentsFormSub) this.additionalCommentsFormSub.unsubscribe();
  }
}
