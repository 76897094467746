<div class="card-btn" [ngClass]="{ selected: selected }" (click)="emitClick()">
  <div class="content-wrapper">
    <div class="icon-container">
      <img [src]="cardData.icon.url" alt="Card Icon" />
    </div>
    <div class="content">
      <h2>{{ cardData.title }}</h2>
      <p>
        {{ cardData.description }}
      </p>
    </div>
  </div>
  <app-button
    class="intro"
    type="filled"
    rightIconSrc="/assets/icons/arrow-right-black.svg"
    [text]="text"
    [isUppercase]="isUppercase"
  />
</div>
