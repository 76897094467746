<app-main-layout
  (manuallyClickNext)="onClickNext()"
  [activateNextButton$]="nextButtonState.activateNext$"
>
  <div class="process-info-wrapper">
    <div class="main-heading">
      <h2 [innerHtml]="pageContent[0]?.dce_page_introduction?.title"></h2>
    </div>
    <div class="steps-wrapper">
      <app-process-info-card
        *ngFor="let step of steps"
        [step]="step"
      ></app-process-info-card>
    </div>
  </div>
</app-main-layout>
