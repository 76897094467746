<app-main-layout [clickNext]="onClickNext" [clickBack]="onClickBack" [activateNextButton$]="nextButtonState.activateNext$">
  <div class="main-heading">
    <h2 [innerHtml]="pageContent[0]?.dce_page_introduction?.title"></h2>
    <div
      [innerHtml]="pageContent[0]?.dce_page_introduction?.text"
      class="cs-inner-html-16"
    ></div>
  </div>
    <div class="selection-list">
      <app-selection-item
        *ngFor="let item of selectionList"
        [item]="item"
        (onItemClick)="selectItem(item)"
        type="single"
      ></app-selection-item>
    </div>
    <div class="text-link">
      <a (click)="openInsideSalesScreen($event)" href="#">{{
        pageContent[2]?.dce_text_link?.link_text
      }}</a>
    </div>
</app-main-layout>
