<app-main-layout [clickBack]="onClickBack" [disableWatchingRoutes]="true">
  <div class="main-heading">
    <h2 [innerHtml]="pageContent[0]?.dce_page_introduction?.title"></h2>
    <div
      [innerHtml]="pageContent[0]?.dce_page_introduction?.text"
      class="cs-inner-html-16"
    ></div>
  </div>
  <div class="accordion">
    <div class="item" *ngFor="let item of installationProcess" [ngClass]="{'active': item.active}" (click)="toggleItem(item)">
      <div class="heading">
        <strong class="title">{{item.title}}</strong>
        <strong class="subtitle">{{item.subtitle}}</strong>
      </div>
      <div class="expanded-block" [innerHtml]="item.text"></div>
    </div>
  </div>
</app-main-layout>