import { Component, OnDestroy, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './shared/components/header/header.component';
import { RouterOutlet } from '@angular/router';
import { LoadingSpinnerComponent } from './shared/components/loading-spinner/loading-spinner.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { NavigationService } from './services/navigation.service';
import { Subscription } from 'rxjs';
import { AppInsightsService } from './services/app-insights.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    HeaderComponent,
    FooterComponent,
    LoadingSpinnerComponent,
  ],
})
export class AppComponent implements OnDestroy {
  sub: Subscription;
  isAppEmbedded: boolean = false;
  title = 'windownation';
  private readonly applicationInsightsService =inject(AppInsightsService);

  constructor(private navigationService: NavigationService) {
    this.sub = this.navigationService.getIsEmbedded().subscribe((res) => {
      this.isAppEmbedded = res;
    });
  }

  ngOnDestroy(): void {
    if (this.sub) this.sub.unsubscribe();
  }
}
