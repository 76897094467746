<div class="step-card">
  <div class="icon-container">
    <img [src]="step.icon.url" alt="Step Icon" />
  </div>
  <div class="content">
    <h2>{{ step.title }}</h2>
    <div
      *ngIf="step.text"
      [innerHtml]="step.text"
      class="cs-inner-html-16"
    ></div>
  </div>
</div>
