import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, Subscription, map, of } from 'rxjs';

import { ContentstackModule } from 'src/modules/contentstack/contentstack.module';
import { ContentstackQueryService } from 'src/app/services/cs.query.service';
import { MainLayoutComponent } from 'src/app/shared/components/main-layout/main-layout.component';
import { SelectionItemComponent } from 'src/app/shared/components/selection-item/selection-item.component';
import { DataService } from 'src/app/services/data.service';
import { FormData } from 'src/app/services/data.model';
import { NAVIGATION_MAP, PAGES } from 'src/app/shared/constants/navigation-map';
import { NavigationService } from 'src/app/services/navigation.service';
import { NextButtonStateService } from 'src/app/services/next-button-state.service';

@Component({
  selector: 'app-project-timeline',
  standalone: true,
  imports: [
    CommonModule,
    ContentstackModule,
    MainLayoutComponent,
    SelectionItemComponent,
  ],
  providers: [ContentstackQueryService],
  templateUrl: './project-timeline.component.html',
  styleUrl: './project-timeline.component.scss',
})
export class ProjectTimelineComponent implements OnInit, OnDestroy {
  pageContent: any = [];
  selectionList: any[] = [];
  sub: Subscription;
  previouslyFilledData: number;
  justShoppingEnum = 876090099;
  windowCount: number = 0;

  constructor(
    private contentstack: ContentstackQueryService,
    private dataService: DataService,
    private navigationService: NavigationService,
    public nextButtonState: NextButtonStateService
  ) {
    this.getEntry();
  }

  ngOnInit() {}

  getEntry() {
    this.contentstack
      .getEntryWithQuery(
        'dce_page',
        { key: 'url', value: '/project-timeline' },
        [],
        []
      )
      .then(
        (entry) => {
          this.pageContent = entry[0][0]?.page_components;
          this.selectionList =
            this.pageContent[1]?.dce_project_timeline?.project_timeline;
          this.setupElements();
          if (this.dataService.id) {
            this.sub = this.dataService
              .getStepsData(this.dataService.id)
              .subscribe((data) => {
                this.prepopulateStepData(data);
              });
          }
        },
        (err) => {}
      );
  }

  prepopulateStepData(data: FormData) {
    if (data?.appointmentRequest?.project?.timeframeEnum) {
      this.previouslyFilledData = Number(
        data.appointmentRequest?.project?.timeframeEnum
      );
      this.updateFormValues();
    }
    if (data?.appointmentRequest?.project?.windowCount) {
      this.windowCount = Number(data.appointmentRequest.project?.windowCount);
    }

    this.checkFormValidity();
  }

  updateFormValues() {
    this.selectionList = this.selectionList.map((item) => {
      const found = this.previouslyFilledData === Number(item.unique_name);
      return found ? { ...item, selected: true } : item;
    });
  }

  checkFormValidity() {
    setTimeout((_) => {
      const selectedItem = this.selectionList.find((item) => item.selected);
      this.nextButtonState.emitActivateNext(!!selectedItem);
    });
  }

  setupElements(): void {
    this.selectionList = this.selectionList.map((item) => {
      return { ...item, selected: false };
    });
  }

  selectItem(item) {
    this.selectionList = this.selectionList.map((listItem) => {
      if (listItem.unique_name === item.unique_name) {
        return { ...listItem, selected: !item.selected };
      } else {
        return { ...listItem, selected: false };
      }
    });
    this.checkFormValidity();
  }

  onClickNext = (): Observable<any> => {
    const selectedItem = this.selectionList.find((item) => item.selected);
    return selectedItem
      ? this.saveData(
          selectedItem,
          NAVIGATION_MAP[PAGES.PROJECT_TIMELINE].nextButton.route
        )
      : of(false);
  };

  onClickBack = (): Observable<any> => {
    const selectedItem = this.selectionList.find((item) => item.selected);
    const nextPage = this.windowCount === 0 ? PAGES.DOOR_COUNT : NAVIGATION_MAP[PAGES.PROJECT_TIMELINE].backButton.route
    return selectedItem
      ? this.saveData(
          selectedItem,
          nextPage
        )
      : of(nextPage);
  };

  saveData(selectedItem, nextPage): Observable<any> {
    const stepsData = { ...this.dataService.stepsData };
    stepsData.appointmentRequest.project.timeframeEnum = Number(
      selectedItem.unique_name
    );
    const sessionData = JSON.parse(stepsData.sessionData);
    sessionData.nextPage = nextPage;
    stepsData.sessionData = JSON.stringify(sessionData);
    return this.dataService.saveStepsData(stepsData).pipe(
      map((res) => {
        return this.windowCount === 0 ? nextPage : true;
      })
    );
  }

  openInsideSalesScreen(e: any) {
    const stepsData = { ...this.dataService.stepsData };
    stepsData.appointmentRequest.project.timeframeEnum = this.justShoppingEnum;
    const sessionData = JSON.parse(stepsData.sessionData);
    sessionData.nextPage = NAVIGATION_MAP[PAGES.PROJECT_TIMELINE].nextButton.route;
    stepsData.sessionData = JSON.stringify(sessionData);
    e.preventDefault();
    this.dataService.saveStepsData(stepsData).subscribe((res) => {
      this.navigationService.navigateToPath(NAVIGATION_MAP[PAGES.PROJECT_TIMELINE].nextButton.route);
    });
  }

  ngOnDestroy(): void {
    if (this.sub) this.sub.unsubscribe();
  }
}
