import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContentstackModule } from 'src/modules/contentstack/contentstack.module';
import { ContentstackQueryService } from 'src/app/services/cs.query.service';
import { ButtonComponent } from 'src/app/shared/components/button/button.component';
import { MainLayoutComponent } from 'src/app/shared/components/main-layout/main-layout.component';
import { DataService } from 'src/app/services/data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-not-a-homeowner',
  standalone: true,
  imports: [
    CommonModule,
    ContentstackModule,
    ButtonComponent,
    MainLayoutComponent,
  ],
  providers: [ContentstackQueryService],
  templateUrl: './not-a-homeowner.component.html',
  styleUrl: './not-a-homeowner.component.scss',
})
export class NotAHomeownerComponent implements OnInit, OnDestroy {
  pageContent: any = [];
  sub: Subscription;
  constructor(private contentstack: ContentstackQueryService, private dataService: DataService) {
    this.getEntry();
  }

  ngOnInit() {
    const stepsData = {...this.dataService.stepsData};
    stepsData.status = 'Abandoned';
    this.sub = this.dataService.saveStepsData(stepsData).subscribe();
  }

  getEntry() {
    this.contentstack
      .getEntryWithQuery(
        'dce_page',
        { key: 'url', value: '/not-a-homeowner' },
        [],
        []
      )
      .then(
        (entry) => {
          this.pageContent = entry[0][0]?.page_components;
        },
        (err) => {}
      );
  }

  onButtonClick = () => {
    window.location.href = `tel:${this.pageContent[1]?.dce_call_us?.button?.link}`;
  }

  ngOnDestroy(): void {
    if(this.sub) this.sub.unsubscribe();
  }
}
