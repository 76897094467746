<div class="main-layout">
  <app-progress-bar [ngClass]="{'hidden': !showProgressBar}" class="progress-bar" />
  <div class="top-buttons-frame">
    <app-button
      *ngIf="backButton"
      class="back"
      [text]="backButton.text"
      type="flat"
      leftIconSrc="/assets/icons/arrow-left.svg"
      [handleClick]="onClickBack"
    />
    <app-button
      *ngIf="exitButton"
      class="exit"
      [text]="exitButton.text"
      type="flat"
      rightIconSrc="/assets/icons/x.svg"
      [handleClick]="onClickExit"
    />
  </div>
  <ng-content></ng-content>
  <div
    class="btn-next-wrapper"
    *ngIf="nextButton"
    [ngClass]="{ centered: nextButton.position === 'center' }"
  >
    <app-button
      [text]="nextButton.text"
      [disabled]="!activateNextButton"
      type="filled"
      [rightIconSrc]="nextButton.noIcon ? '' : '/assets/icons/arrow-right-black.svg'"
      [handleClick]="onClickNext"
    />
  </div>
</div>
