import {
  Component,
  Host,
  Input,
  OnInit,
  Optional,
  SkipSelf,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ControlContainer,
  ControlValueAccessor,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';

interface RadioItem {
  label: string;
  formControlName: string;
  value?: number;
}

@Component({
  selector: 'app-radio',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: RadioComponent,
    },
  ],
  templateUrl: './radio.component.html',
  styleUrl: './radio.component.scss',
})
export class RadioComponent implements OnInit, ControlValueAccessor {
  @Input() radioGroup: RadioItem[];
  @Input() rounded?: boolean = false;
  formGroup: FormGroup;

  constructor(
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer
  ) {}

  // mock functions for ControlValueAccessor
  writeValue(obj: any): void {}
  registerOnChange(fn: any): void {}
  registerOnTouched(fn: any): void {}

  ngOnInit(): void {
    this.formGroup = this.controlContainer.control as FormGroup;
  }

  pickValue(item: RadioItem): number | string {
    if (typeof item.value === 'number' || typeof item.value === 'string') {
      return item.value;
    }
    return item.label;
  }
}
