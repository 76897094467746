import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-process-info-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './process-info-card.component.html',
  styleUrl: './process-info-card.component.scss'
})
export class ProcessInfoCardComponent {
  @Input() step: any;
}
