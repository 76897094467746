<app-main-layout [clickNext]="onClickNext" [clickBack]="onClickBack" [activateNextButton$]="nextButtonState.activateNext$">
  <div class="main-heading">
    <h2 [innerHtml]="pageContent[0]?.dce_page_introduction?.title"></h2>
  </div>
  <div class="images-block">
    <img *ngIf="doorImage" width="102" height="154" [src]="doorImage" alt="" />
  </div>
  <app-counter
    class="counter-input"
    [step]="counterStep"
    [min]="counterMinValue"
    [max]="counterMaxValue"
    type="doors"
    label="Number of Doors"
    [initialValue]="counterInitialValue"
    (valueChange)="onCounterValueChange($event)"
  >
  </app-counter>
</app-main-layout>
