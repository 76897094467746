import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoadingSpinnerState } from './loading-spinner.model';

@Injectable({
  providedIn: 'root'
})
export class LoadingSpinnerService {
  private _isLoading = new BehaviorSubject<LoadingSpinnerState>(false);
  isLoading = this._isLoading.asObservable();
  keepShowing = false;

  show() {
    this._isLoading.next(true);
  }

  hide(keepShowing?) {
    this._isLoading.next('complete');
    if(!keepShowing){
      setTimeout(_ => {
        this._isLoading.next(false);
      }, 100);
    }

  }
}
