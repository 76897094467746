import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { Config } from '../environments/environment';
import { CsbaseAppConfigToken } from '../modules/contentstack/contentstack.module';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorInterceptor } from './services/interceptor.service';
import { provideAppInsights } from './services/app-insights-provider';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    { provide: CsbaseAppConfigToken, useValue: Config },
    provideHttpClient(
      withInterceptorsFromDi()
    ),
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    provideAppInsights()
  ]
};
