import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { ContentstackQueryService } from 'src/app/services/cs.query.service';
import { ContentstackModule } from 'src/modules/contentstack/contentstack.module';
import { FormGroupComponent } from 'src/app/shared/components/form-group/form-group.component';
import { MainLayoutComponent } from 'src/app/shared/components/main-layout/main-layout.component';
import { DataService } from 'src/app/services/data.service';
import { RadioComponent } from '../../shared/components/radio/radio.component';
import { ButtonComponent } from 'src/app/shared/components/button/button.component';
import {
  FORBIDDEN_PHONES,
  forbiddenPhoneValidator,
} from 'src/app/shared/constants/phone-validator';
import { Observable, Subscription, map, of } from 'rxjs';
import { FormData, Property } from 'src/app/services/data.model';
import { NAVIGATION_MAP, PAGES } from 'src/app/shared/constants/navigation-map';
import { NextButtonStateService } from 'src/app/services/next-button-state.service';
import { VALIDATION_PATTERNS } from 'src/app/shared/constants/text-field';

@Component({
  selector: 'app-homeowner',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ContentstackModule,
    MainLayoutComponent,
    FormGroupComponent,
    RadioComponent,
    ButtonComponent,
  ],
  providers: [ContentstackQueryService],
  templateUrl: './homeowner.component.html',
  styleUrl: './homeowner.component.scss',
})
export class HomeownerComponent implements OnInit, OnDestroy {
  pageContent: any[] = [];
  homeownerForm: FormGroup;
  radioGroup = [];
  homeownersList: Array<FormGroup> = [];
  previouslyFilledData: Property;
  sub: Subscription;
  formChangesSub: Subscription;
  homeownersSectionsSub: Subscription = new Subscription();

  constructor(
    private contentstack: ContentstackQueryService,
    private fb: FormBuilder,
    private dataService: DataService,
    public nextButtonState: NextButtonStateService
  ) {
    this.getEntry();
  }

  ngOnInit(): void {
    this.buildForm();
  }

  getEntry() {
    this.contentstack
      .getEntryWithQuery(
        'dce_page',
        { key: 'url', value: '/homeowner-list' },
        [],
        []
      )
      .then(
        (entry) => {
          this.pageContent = entry[0][0]?.page_components;
          if (this.dataService.id) {
            this.sub = this.dataService
              .getStepsData(this.dataService.id)
              .subscribe((data) => {
                this.prepopulateStepData(data);
              });
          }
        },
        (err) => {}
      );
  }

  buildForm() {
    this.homeownerForm = this.fb.group({
      onlyHomeowner: ['', Validators.required],
    });
    this.homeownerForm.controls['onlyHomeowner'].valueChanges.subscribe(
      (value) => {
        if (
          value ===
            this.pageContent[1]?.dce_homeowner_form_data?.radio_buttons[1]
              .unique_name &&
          !this.homeownersList.length
        ) {
          this.homeownersList.push(
            this.fb.group({
              fullName: [
                '',
                [
                  Validators.required,
                  Validators.maxLength(100),
                  Validators.pattern(VALIDATION_PATTERNS.text),
                ],
              ],
              phone: [
                '',
                [
                  Validators.pattern('^[0-9]{10}$'),
                  forbiddenPhoneValidator(FORBIDDEN_PHONES),
                ],
              ],
            })
          );
        }
        if (this.homeownerForm && value === 'multiple_homeowners') {
          this.trackHomeownersValidation();
        }
        this.checkFormValidity();
      }
    );
  }

  checkFormValidity() {
    setTimeout((_) => {
      const onlyHomeowner =
        this.homeownerForm.value.onlyHomeowner === 'the_only_homeowner';
      const find_invalid = this.homeownersList.some((homeownerForm) => {
        return homeownerForm.invalid;
      });

      this.nextButtonState.emitActivateNext(
        this.homeownerForm.valid && (onlyHomeowner || !find_invalid)
      );
    });
  }

  updateFormValues() {
    this.radioGroup =
      this.pageContent[1]?.dce_homeowner_form_data?.radio_buttons.map(
        (item: { title: string; unique_name: string }) => {
          return {
            formControlName: 'onlyHomeowner',
            label: item.title,
            id: item.unique_name,
            value: item.unique_name,
          };
        }
      );
    this.homeownerForm.patchValue({
      onlyHomeowner: this.previouslyFilledData?.ownership?.multipleOwners
        ? 'multiple_homeowners'
        : 'the_only_homeowner',
    });
    if (this.previouslyFilledData?.ownership?.additionalOwners?.length) {
      this.homeownersList = [];
      this.previouslyFilledData?.ownership?.additionalOwners.map((owner) => {
        this.homeownersList.push(
          this.fb.group({
            fullName: [owner.fullName || '', Validators.required],
            phone: [
              owner.phone || '',
              [
                Validators.pattern('^[0-9]{10}$'),
                forbiddenPhoneValidator(FORBIDDEN_PHONES),
              ],
            ],
          })
        );
      });
    }
    this.trackHomeownersValidation();
    this.checkFormValidity();
  }

  prepopulateStepData(data: FormData) {
    if (data?.appointmentRequest?.property?.ownership) {
      this.previouslyFilledData = data.appointmentRequest.property;
    }
    this.updateFormValues();
  }

  makeLabel(template: string, index: number): string {
    return `${template} ${index + 1}`;
  }

  onAddHomeowner(): void {
    const newGroup = this.fb.group({
      fullName: ['', Validators.required],
      phone: [
        '',
        [
          Validators.pattern('^[0-9]{10}$'),
          forbiddenPhoneValidator(FORBIDDEN_PHONES),
        ],
      ],
    });

    const newGroupSub = newGroup.valueChanges.subscribe((value) => {
      this.checkFormValidity();
    });
    this.homeownersSectionsSub.add(newGroupSub);
    this.homeownersList.push(newGroup);
    this.checkFormValidity();
  }

  trackHomeownersValidation() {
    this.homeownersList.forEach((homeownerForm) => {
      this.homeownersSectionsSub.add(
        homeownerForm.valueChanges.subscribe((value) => {
          this.checkFormValidity();
        })
      );
    });
  }

  onRemoveHomeOwner(index: number): void {
    this.homeownersList.splice(index, 1);
    if (!this.homeownersList.length) {
      this.homeownerForm.patchValue({ onlyHomeowner: 'the_only_homeowner' });
    }
    this.checkFormValidity();
  }

  onClickNext = (): Observable<any> => {
    // check checkbox first
    if (this.homeownerForm.valid) {
      return this.saveData(
        NAVIGATION_MAP[PAGES.HOMEOWNER_LIST].nextButton.route
      );
    }
    this.homeownerForm.markAllAsTouched();
    return of(false);
  };

  onClickBack = (): Observable<any> => {
    return this.homeownerForm.valid
      ? this.saveData(
          NAVIGATION_MAP[PAGES.HOMEOWNER_LIST].backButton.route,
          true
        )
      : of(true);
  };

  saveData(nextPage, isBack?: boolean): Observable<any> {
    let homeowners = [];
    // check if user has homeowners
    if (this.homeownerForm.value.onlyHomeowner === 'multiple_homeowners') {
      // check all homeowners forms valid
      if (
        this.homeownersList.some((homeownerForm) => {
          if (homeownerForm.invalid) {
            homeownerForm.markAllAsTouched();
          }
          return homeownerForm.invalid;
        })
      ) {
        return of(isBack);
      }
      // mapping homeownersList for data for the backend
      this.homeownersList.forEach((homeownerForm) => {
        if (homeownerForm.valid) {
          homeowners.push(homeownerForm.value);
        } else {
          homeownerForm.markAllAsTouched();
        }
      });
      // double check validity for every form
      if (homeowners.length !== this.homeownersList.length) {
        return of(isBack);
      }
    }
    let data = {
      onlyHomeowner: this.homeownerForm.value.onlyHomeowner,
    };
    if (homeowners.length) {
      data['homeowners'] = homeowners;
    }

    const stepsData = { ...this.dataService.stepsData };
    stepsData.appointmentRequest.property.ownership.multipleOwners =
      this.homeownerForm.value.onlyHomeowner === 'multiple_homeowners';
    stepsData.appointmentRequest.property.ownership.additionalOwners =
      this.homeownersList.map((item) => item.value);
    const sessionData = JSON.parse(stepsData.sessionData);
    sessionData.nextPage = nextPage;
    stepsData.sessionData = JSON.stringify(sessionData);

    return this.dataService.saveStepsData(stepsData).pipe(
      map((res) => {
        return true;
      })
    );
  }

  ngOnDestroy(): void {
    if (this.sub) this.sub.unsubscribe();
    if (this.formChangesSub) this.formChangesSub.unsubscribe();
    if (this.homeownersSectionsSub) this.homeownersSectionsSub.unsubscribe();
  }
}
