import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function forbiddenPhoneValidator(
  forbiddenPhones: string[] = FORBIDDEN_PHONES
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const phone = control.value;
    const isForbidden = forbiddenPhones.includes(phone);

    return isForbidden ? { forbiddenPhone: { value: phone } } : null;
  };
}

export const FORBIDDEN_PHONES = [
  '0000000000',
  '1111111111',
  '2222222222',
  '3333333333',
  '4444444444',
  '5555555555',
  '6666666666',
  '7777777777',
  '8888888888',
  '9999999999',
  '1234567890',
  '0987654321',
  '1212121212',
  '2323232323',
  '3434343434',
  '4545454545',
  '5656565656',
  '6767676767',
  '7878787878',
  '8989898989',
  '1231231234',
  '3213214321',
  '1231231231',
];
