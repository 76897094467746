<button
  class="btn-core"
  (click)="handleClick()"
  [ngClass]="{
    flat: type === 'flat',
    filled: type === 'filled',
    outline: type === 'outline',
    readmore: type === 'readMore',
    disabled: disabled,
    'full-width': fullWidth,
    uppercase: isUppercase,
  }"
  [disabled]="disabled"
  [type]="buttonType"
>
  <ng-container *ngIf="leftIconSrc">
    <span class="icon-frame">
      <img class="icon" [src]="leftIconSrc" alt="logo" aria-hidden="true" />
    </span>
  </ng-container>
  {{ text }}
  <ng-container *ngIf="rightIconSrc">
    <span class="icon-frame">
      <img class="icon" [src]="rightIconSrc" alt="logo" aria-hidden="true" />
    </span>
  </ng-container>
</button>
