import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContentstackModule } from 'src/modules/contentstack/contentstack.module';
import { ContentstackQueryService } from 'src/app/services/cs.query.service';
import { MainLayoutComponent } from 'src/app/shared/components/main-layout/main-layout.component';
import { ReplaceUsernamePipe } from 'src/app/shared/pipes/replace-username.pipe';
import { BlogPostCardComponent } from 'src/app/shared/components/blog-post-card/blog-post-card.component';
import { DataService } from 'src/app/services/data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-do-not-service-area',
  standalone: true,
  imports: [
    CommonModule,
    ContentstackModule,
    MainLayoutComponent,
    ReplaceUsernamePipe,
    BlogPostCardComponent,
  ],
  providers: [ContentstackQueryService],
  templateUrl: './do-not-service-area.component.html',
  styleUrl: './do-not-service-area.component.scss',
})
export class DoNotServiceAreaComponent implements OnInit, OnDestroy {
  pageContent: any = [];
  name: string = '';
  blogPosts: any;
  sub: Subscription;

  constructor(
    private contentstack: ContentstackQueryService,
    private dataService: DataService
  ) {
    this.getEntry();
  }

  ngOnInit() {
    const stepsData = {...this.dataService.stepsData};
    stepsData.status = 'Abandoned';
    this.sub = this.dataService.saveStepsData(stepsData).subscribe();
  }

  getEntry() {
    this.contentstack
      .getEntryWithQuery(
        'dce_page',
        { key: 'url', value: '/do-not-service-area' },
        [],
        []
      )
      .then(
        (entry) => {
          this.pageContent = entry[0][0]?.page_components;
          this.blogPosts = this.pageContent[1]?.dce_blog_posts;
          this.name = this.dataService.username;
        },
        (err) => {}
      );
  }

  ngOnDestroy(){
    if(this.sub) this.sub.unsubscribe();
  }
}
