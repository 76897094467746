import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NextButtonStateService {
  private activateNextSubject = new Subject<boolean>();
  activateNext$: Observable<any> = this.activateNextSubject.asObservable();

  emitActivateNext(isActive: boolean): void {
    this.activateNextSubject.next(isActive);
  }
}
