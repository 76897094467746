import { CommonModule } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { TimerService } from 'src/app/services/timer.service';
import { ButtonComponent } from '../button/button.component';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-timer',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  templateUrl: './timer.component.html',
  styleUrl: './timer.component.scss',
})
export class TimerComponent implements OnDestroy {
  time = this.timerService.time;
  timeInSeconds = this.timerService.timeInSeconds;
  running = this.timerService.running;
  danger = false;
  sub: Subscription;
  runningSub: Subscription;
  alertSub: Subscription;
  showAlert = this.timerService.showAlert;

  constructor(private timerService: TimerService, private navigationService: NavigationService) {
    this.sub = this.timeInSeconds.subscribe((value) => {
      if (value < 60) {
        this.danger = true;
      } else {
        this.danger = false;
      }
    });
    this.runningSub = this.timerService.running$.subscribe(value => {
      this.running = value;
    });
    this.alertSub = this.timerService.showAlert$.subscribe(value => {
      this.showAlert = value;
    });
  }

  start() {
    this.timerService.start();
  }

  stop() {
    this.timerService.stop();
  }

  reset() {
    this.timerService.reset();
  }

  goToTimeBooking = (): void => {
    this.timerService.removeAlert()
    this.navigationService.navigateToPath('time-date-booking');
  }

  ngOnDestroy(): void {
    if (this.sub) this.sub.unsubscribe();
    if (this.runningSub) this.runningSub.unsubscribe();
    if (this.alertSub) this.alertSub.unsubscribe();
  }
}
