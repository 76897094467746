<app-main-layout [clickBack]="onClickBack" [disableWatchingRoutes]="true">
  <div class="block">
    <div class="main-heading">
      <h2 [innerHtml]="pageContent[0]?.dce_page_introduction?.title | replaceUsername:name"></h2>
      <div
        [innerHtml]="pageContent[0]?.dce_page_introduction?.text"
        class="cs-inner-html-16"
      ></div>
    </div>
  </div>
    <div class="block easy-steps" *ngIf="steps">
      <h3 class="mb-24 h5-m">{{steps?.title}}</h3>
      <ol class="custom-ordered-list">
        <li *ngFor="let step of steps.steps_list">{{step.title}}</li>
      </ol>
    </div>
    <div class="blog-posts" *ngIf="blogPosts">
      <h3 class="h5-m">{{blogPosts.title}}</h3>
      <div class="blog-posts-frame">
        <app-blog-post-card *ngFor="let card of blogPosts.blog_posts_list" [card]="card" />
      </div>
    </div>
</app-main-layout>