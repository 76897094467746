<app-main-layout
  [clickNext]="onClickNext"
  [clickBack]="onClickBack"
  [activateNextButton$]="nextButtonState.activateNext$"
>
  <div class="main-heading">
    <h2
      [innerHtml]="
        pageContent[0]?.dce_page_introduction?.title"
    ></h2>
    <div
      [innerHtml]="pageContent[0]?.dce_page_introduction?.text"
      class="cs-inner-html-16"
    ></div>
  </div>
  <div class="selection-list">
    <app-selection-item
      *ngFor="let item of selectionList"
      [item]="item"
      (onItemClick)="selectItem(item)"
    ></app-selection-item>
  </div>
  <div class="text-link" *ngIf="!showOtherBlock">
    <a (click)="switchOther(true, $event)" href="#">Other</a>
  </div>
  <form [formGroup]="otherIssueForm" *ngIf="showOtherBlock">
    <div class="input">
      <button
        class="remove-btn"
        (click)="switchOther(false)"
      >
        <img
          class="icon"
          src="/assets/icons/trash-2.svg"
          alt="remove-btn"
          aria-hidden="true"
        />
      </button>
      <app-form-group
        label="Other"
        id="issue"
        [required]="true"
        [counter]="otherTextfieldMaxLength"
        formControlName="issue"
        placeholder="Enter your window issue here"
      >
      </app-form-group>
    </div>
  </form>
</app-main-layout>
