<app-main-layout
  [clickNext]="onClickNext"
  [clickBack]="onClickBack"
  [activateNextButton$]="nextButtonState.activateNext$"
>
  <div class="offer-bar booking" *ngIf="showNotification" [ngClass]="fadeClass">
    <div class="frame">
      <div class="icon">
        <img src="assets/icons/offer-icon.svg" alt="">
      </div>
      <div class="offer-bar-description">
        <strong class="offer-title">{{pageContent[4]?.dce_tip_text?.text}}</strong>
      </div>
    </div>
  </div>
  <app-ppl-card [isPPL]="isPPL" [text]="pageContent[3]?.dce_tip_text?.text"></app-ppl-card>
  <div class="main-heading">
    <h2 [innerHtml]="pageContent[0]?.dce_page_introduction?.title"></h2>
    <div
      [innerHtml]="pageContent[0]?.dce_page_introduction?.text"
      class="cs-inner-html-16"
    ></div>
  </div>
  <form [formGroup]="scheduleForm" *ngIf="isDataLoaded" class="form">
    <div class="calendar">
      <div class="days">
        <div
          class="days-frame"
          *ngIf="
            calendarData[activeChunkNumber] &&
            calendarData[activeChunkNumber]?.length
          "
        >
          <button
            class="control prev"
            aria-label="Previous"
            (click)="goBackward()"
            [disabled]="activeChunkNumber === 0"
          >
            <img src="/assets/icons/arrow-left.svg" alt="arrow-left" />
          </button>
          <div
            class="day"
            *ngFor="let day of calendarData[activeChunkNumber]"
            [ngClass]="{
              active: day.date === activeDate,
              disabled: day.disabled
            }"
            (click)="setActiveDay(day)"
            role="button"
          >
            <span class="title">{{ day.dayOfTheWeek }}</span>
            <span class="date">{{ day.formattedDate }}</span>
          </div>
          <button
            class="control next"
            aria-label="Next"
            (click)="goForward()"
            [disabled]="activeChunkNumber === this.numberOfChunks - 1"
          >
            <img src="/assets/icons/arrow-right-black.svg" alt="arrow-right" />
          </button>
        </div>
      </div>
      <div
        class="time-slots"
        *ngIf="getSlots.slotsCount > 0; else noSlotsTemplate"
      >
        <div class="period" *ngFor="let day of getSlots.slots">
          <span class="title">{{ day.period }}</span>
          <div
            class="slot"
            *ngFor="let period of day.slots"
            [ngClass]="{ selected: period.id === selectedTime }"
          >
            <button (click)="selectTime(period)">{{ period.time }}</button>
          </div>
        </div>
      </div>
    </div>
    <app-checkbox
      *ngIf="isDataLoaded && getSlots.slotsCount > 0"
      formControlName="allAttend"
      label="All homeowners/decision makers will be able to attend."
    ></app-checkbox>
    <div class="text-link">
      <a (click)="noneWorksForMe($event)" href="#">
        {{ pageContent[2]?.dce_text_link?.link_text }}</a
      >
    </div>
  </form>
</app-main-layout>

<ng-template #noSlotsTemplate>
  <p class="no-appointment">*No appointment slots available for this day</p>
</ng-template>
