import { Component, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { ProcessInfoCardComponent } from '../../shared/components/process-info-card/process-info-card.component';
import { MainLayoutComponent } from '../../shared/components/main-layout/main-layout.component';
import { ContentstackModule } from '../../../modules/contentstack/contentstack.module';
import { ContentstackQueryService } from '../../services/cs.query.service';
import { ProcessInfoItem } from '../app.model';
import { DataService } from 'src/app/services/data.service';
import { NAVIGATION_MAP } from 'src/app/shared/constants/navigation-map';
import { NavigationService } from 'src/app/services/navigation.service';
import { NextButtonStateService } from 'src/app/services/next-button-state.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-exploratory-process-info',
  standalone: true,
  imports: [
    CommonModule,
    ContentstackModule,
    ProcessInfoCardComponent,
    MainLayoutComponent,
  ],
  providers: [ContentstackQueryService],
  templateUrl: './exploratory-process-info.component.html',
  styleUrl: './exploratory-process-info.component.scss',
})
export class ExploratoryProcessInfoComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  pageContent = {};
  steps: ProcessInfoItem[] = [];
  subscription: Subscription;
  url: string;

  constructor(
    private contentstack: ContentstackQueryService,
    private dataService: DataService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    public nextButtonState: NextButtonStateService,
    private router: Router
  ) {
    this.subscription = this.route.url.subscribe((segments) => {
      this.url = segments.map((s) => s.path).join('/');
    });
  }

  ngOnInit(): void {
    this.getEntry();
  }

  ngAfterViewInit() {
    this.nextButtonState.emitActivateNext(true);
  }

  getEntry() {
    this.contentstack
      .getEntryWithQuery(
        'dce_page',
        { key: 'url', value: '/exploratory-process-info' },
        [],
        []
      )
      .then(
        (entry) => {
          this.pageContent = entry[0][0]?.page_components;
          this.steps = this.pageContent[1]?.process_info?.process_steps;
        },
        (err) => {}
      );
  }

  onClickNext = () => {
    this.navigationService.navigateToPath(
      NAVIGATION_MAP[this.url].nextButton.route
    );
  };

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
