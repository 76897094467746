import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";

@Component({
  selector: "app-button",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./button.component.html",
  styleUrl: "./button.component.scss",
})
export class ButtonComponent {
  @Input() text: string = "";
  @Input() leftIconSrc?: string = "";
  @Input() rightIconSrc?: string = "";
  @Input() handleClick: Function = () => {};
  @Input() type: "flat" | "filled" | "outline" | "readMore" = "flat";
  @Input() fullWidth?: boolean;
  @Input() disabled?: boolean;
  @Input() buttonType: string = "button";
  @Input() isUppercase?: boolean = false;
}
