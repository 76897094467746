import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContentstackModule } from 'src/modules/contentstack/contentstack.module';
import { ContentstackQueryService } from 'src/app/services/cs.query.service';
import { MainLayoutComponent } from 'src/app/shared/components/main-layout/main-layout.component';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-financing-plans',
  standalone: true,
  imports: [CommonModule, ContentstackModule, MainLayoutComponent],
  providers: [ContentstackQueryService],
  templateUrl: './financing-plans.component.html',
  styleUrl: './financing-plans.component.scss',
})
export class FinancingPlansComponent implements OnInit {
  pageContent: any = [];
  plans: any[] = [];

  constructor(private contentstack: ContentstackQueryService) {
    this.getEntry();
  }

  ngOnInit() {}

  getEntry() {
    this.contentstack
      .getEntryWithQuery(
        'dce_page',
        { key: 'url', value: '/financing-plans' },
        [],
        []
      )
      .then(
        (entry) => {
          this.pageContent = entry[0][0]?.page_components;
          this.plans = this.pageContent[1]?.dce_financing_plans?.plan;
        },
        (err) => {}
      );
  }

  onClickBack = (): Observable<any> => {
    return of(true);
  };
}
