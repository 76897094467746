import { Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Router } from "@angular/router";

import { ContentstackModule } from "src/modules/contentstack/contentstack.module";
import { ContentstackQueryService } from "src/app/services/cs.query.service";
import { MainLayoutComponent } from 'src/app/shared/components/main-layout/main-layout.component';
import { Observable, of } from 'rxjs';

@Component({
  selector: "app-installation-process",
  standalone: true,
  imports: [
    CommonModule,
    ContentstackModule,
    MainLayoutComponent
  ],
  providers: [ContentstackQueryService],
  templateUrl: "./installation-process.component.html",
  styleUrl: "./installation-process.component.scss",
})
export class InstallationProcessComponent implements OnInit {
  pageContent: any = [];
  installationProcess = [];

  constructor(
    private contentstack: ContentstackQueryService,
    private router: Router
  ) {
    this.getEntry();
  }

  ngOnInit() {}

  getEntry() {
    this.contentstack
      .getEntryWithQuery(
        'dce_page',
        { key: 'url', value: "/installation-process" },
        [],
        []
      )
      .then(
        (entry) => {
          this.pageContent = entry[0][0]?.page_components;
          this.installationProcess = this.pageContent[1]?.dce_installation_process?.installation_process_list;
        },
        (err) => {}
      );
  }

  toggleItem(item){
    this.installationProcess.map(listItem => listItem.title === item.title ? listItem.active = !listItem.active : listItem.active = false)
  }

  onClickBack = (): Observable<any> => {
    return of(true);
  };

}
