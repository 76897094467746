import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { parseISO, getYear, format, parse, isPast } from 'date-fns';

import { ContentstackQueryService } from 'src/app/services/cs.query.service';
import { DataService } from 'src/app/services/data.service';
import { MainLayoutComponent } from 'src/app/shared/components/main-layout/main-layout.component';
import { ContentstackModule } from 'src/modules/contentstack/contentstack.module';
import { CheckboxComponent } from 'src/app/shared/components/checkbox/checkbox.component';
import {
  AppointmentInfo,
  ContactInfo,
  FormData,
} from 'src/app/services/data.model';
import { Observable, Subscription, map, of } from 'rxjs';
import { formatPhoneNumber } from 'src/app/shared/utils/utils';
import { NAVIGATION_MAP, PAGES } from 'src/app/shared/constants/navigation-map';
import { NextButtonStateService } from 'src/app/services/next-button-state.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { TimerComponent } from 'src/app/shared/components/timer/timer.component';
import { TimerService } from 'src/app/services/timer.service';
import { marketingAttributes } from 'src/app/shared/constants/marketing-params';

@Component({
  selector: 'app-confirmation',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ContentstackModule,
    MainLayoutComponent,
    CheckboxComponent,
    TimerComponent,
  ],
  providers: [ContentstackQueryService],
  templateUrl: './confirmation.component.html',
  styleUrl: './confirmation.component.scss',
})
export class ConfirmationComponent implements OnInit, OnDestroy {
  pageContent: any[] = [];
  confirmationForm: FormGroup;
  data: FormData;
  confirmationContent;
  sub: Subscription;
  bookingTimeSub: Subscription;
  routeSub: Subscription;
  appointmentInfo: any;
  contactInfo: ContactInfo;
  windowCount: number;
  doorCount: number = 0;
  appointmentError = false;
  confirmationFormSub: Subscription;
  marketingParams: any[];

  constructor(
    private fb: FormBuilder,
    private contentstack: ContentstackQueryService,
    private dataService: DataService,
    private timerService: TimerService,
    public nextButtonState: NextButtonStateService,
    private navigationService: NavigationService,
    private route: ActivatedRoute
  ) {
    this.routeSub = this.route.queryParams.subscribe((params) => {
      this.marketingParams = Object.keys(params)
        .filter((key) => marketingAttributes.includes(key))
        .map((key) => ({ parameter: key, value: params[key] }));
    });
  }

  ngOnInit(): void {
    this.getEntry();
    this.confirmationForm = this.fb.group({
      attendanceConfirmation: [
        true,
        [Validators.required, Validators.requiredTrue],
      ],
    });
    this.data = this.dataService.getFormData();
    this.subscribeToFormChanges();
  }

  getEntry() {
    this.contentstack
      .getEntryWithQuery(
        'dce_page',
        { key: 'url', value: '/confirmation' },
        [],
        []
      )
      .then(
        (entry) => {
          this.pageContent = entry[0][0]?.page_components;
          this.confirmationContent = this.pageContent[1]?.dce_confirmation;
          if (this.dataService.id) {
            this.sub = this.dataService
              .getStepsData(this.dataService.id)
              .subscribe((data) => {
                this.prepopulateStepsData(data);
              });
          }
        },
        (err) => {}
      );
  }

  subscribeToFormChanges(): void {
    this.confirmationFormSub = this.confirmationForm.valueChanges.subscribe(
      (value) => {
        this.checkFormValidity();
      }
    );
  }

  checkFormValidity() {
    setTimeout((_) => {
      this.nextButtonState.emitActivateNext(this.confirmationForm.valid);
    });
  }

  setAppointmentInfo(appointmentInfo: AppointmentInfo, zipCode: string) {
    if (appointmentInfo && !appointmentInfo.noneWorksForMe) {
      const date = appointmentInfo.selectedTimeDate;
      const parsedDate = parseISO(date);
      this.bookingTimeSub = this.dataService
        .getTimeBookingData(date, zipCode)
        .subscribe((data: { appointmentBlocks: any[] }) => {
          const timeBlock = this.findTimeBlockById(
            data.appointmentBlocks,
            appointmentInfo.selectedTime
          );
          this.appointmentInfo = {
            date,
            ...this.findTimeBlockById(
              data.appointmentBlocks,
              appointmentInfo.selectedTime
            ),
            ...{
              year: getYear(parsedDate),
              formattedDate: format(parseISO(date), 'LLL d'),
            },
            day: format(parsedDate, 'EEE'),
            startTime: format(
              parse(appointmentInfo.selectedStartTime, 'HH:mm:ss', new Date()),
              'hh:mm a'
            ),
            endTime: format(
              parse(appointmentInfo.selectedEndTime, 'HH:mm:ss', new Date()),
              'hh:mm a'
            ),
          };
          // TODO: validate if the previously selected date is in the past
          // const past = isPast(
          //   `${appointmentInfo?.selectedTimeDate}T${appointmentInfo?.selectedEndTime}`
          // );
          if (!timeBlock && !appointmentInfo.noAvailableTimeSlots) {
            this.updateFormStatus(true, {
              appointment: 'Appointment Error!',
            });
          } else {
            this.updateFormStatus(false, null);
          }
        });
    } else if (appointmentInfo && appointmentInfo.noneWorksForMe) {
      this.appointmentInfo = appointmentInfo;
    }
  }

  updateFormStatus(
    isError: boolean,
    errors: { [key: string]: string } | null
  ): void {
    this.appointmentError = isError;
    this.confirmationForm.setErrors(errors);
    this.confirmationForm.markAllAsTouched();
    this.confirmationForm.updateValueAndValidity();
  }

  findTimeBlockById(data, id: string) {
    for (const day of data) {
      const timeBlock = day.timeBlocks.find((block) => block.id === id);
      if (timeBlock) {
        return {
          timeBlock,
          day: day.dayOfTheWeek,
          startTime: format(
            parse(timeBlock.startTime, 'HH:mm:ss', new Date()),
            'hh:mm a'
          ),
          endTime: format(
            parse(timeBlock.endTime, 'HH:mm:ss', new Date()),
            'hh:mm a'
          ),
        };
      }
    }
    return null;
  }

  prepopulateStepsData(data: FormData) {
    const sessionData = JSON.parse(data.sessionData);
    if (sessionData.appointmentInfo) {
      this.setAppointmentInfo(
        sessionData.appointmentInfo,
        data?.appointmentRequest?.property?.zipcode
      );
      this.updateFormStatus(false, null);
    } else {
      this.updateFormStatus(true, {
        appointment: 'Appointment Error!',
      });
    }
    if (data.appointmentRequest.customer) {
      this.contactInfo = {
        primaryEmail: data.appointmentRequest.customer.email,
        primaryPhone: data.appointmentRequest.customer.phonePrimary,
        contactPreference: data.appointmentRequest.marketingConsent,
      };
      this.contactInfo.primaryPhone = formatPhoneNumber(
        this.contactInfo.primaryPhone
      );
    }

    if (data.appointmentRequest.project.windowCount)
      this.windowCount = data.appointmentRequest.project.windowCount;

    if (data.appointmentRequest.project.doorCount)
      this.doorCount = data.appointmentRequest.project.doorCount;
  }

  onClickNext = (): Observable<any> => {
    if (
      this.confirmationForm.valid &&
      this.confirmationForm.value.attendanceConfirmation
    ) {
      return this.saveData(
        NAVIGATION_MAP[PAGES.CONFIRMATION].nextButton.route,
        true
      );
    }
    this.confirmationForm.markAllAsTouched();
    return of(false);
  };

  onClickBack = (): Observable<any> => {
    return this.confirmationForm.valid &&
      this.confirmationForm.value.attendanceConfirmation
      ? this.saveData(NAVIGATION_MAP[PAGES.CONFIRMATION].backButton.route)
      : of(true);
  };

  saveData(nextPage, isNext?): Observable<any> {
    const stepsData = { ...this.dataService.stepsData };

    stepsData.appointmentRequest.marketingAttribution.submissionPageUrlParameters =
      this.marketingParams;
    stepsData.appointmentRequest.marketingAttribution.referrerUri =
      document?.referrer || '';
    stepsData.appointmentRequest.marketingAttribution.submissionPageUrl =
      window?.location?.href || '';

    if (isNext) {
      stepsData.status = stepsData.appointmentRequest.appointment
        ? 'Completed'
        : 'CallToSet';
    }
    const sessionData = JSON.parse(stepsData.sessionData);
    sessionData.nextPage = nextPage;
    stepsData.sessionData = JSON.stringify(sessionData);
    return this.dataService.saveStepsData(stepsData).pipe(
      map((res) => {
        if (isNext && this.timerService) this.timerService.stop();
        return true;
      })
    );
  }

  goToTimeBooking() {
    this.navigationService.navigateToPath(PAGES.TIME_DATE_BOOKING);
  }

  ngOnDestroy(): void {
    if (this.sub) this.sub.unsubscribe();
    if (this.bookingTimeSub) this.bookingTimeSub.unsubscribe();
    if (this.routeSub) this.routeSub.unsubscribe();
  }
}
