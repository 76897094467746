import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

import { ContentstackModule } from 'src/modules/contentstack/contentstack.module';
import { ContentstackQueryService } from 'src/app/services/cs.query.service';
import { MainLayoutComponent } from 'src/app/shared/components/main-layout/main-layout.component';
import { SelectionItemComponent } from 'src/app/shared/components/selection-item/selection-item.component';
import { Option } from '../app.model';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-financing-education',
  standalone: true,
  imports: [
    CommonModule,
    ContentstackModule,
    MainLayoutComponent,
    SelectionItemComponent,
  ],
  providers: [ContentstackQueryService],
  templateUrl: './financing-education.component.html',
  styleUrl: './financing-education.component.scss',
})
export class FinancingEducationComponent implements OnInit {
  pageContent: any = {};
  loanTerm: {
    title: string;
    text: string;
    loan_term_options: Option[];
  };
  downPayment: {
    title: string;
    text: string;
    down_payment_options: Option[];
  };
  logicMap: {
    initial_cost: string;
    map: [
      {
        unique_names_combination: string;
        value: string;
      }
    ];
  };
  percentageIncrease: number = 0;
  finalValue: string;

  constructor(
    private contentstack: ContentstackQueryService,
    private router: Router
  ) {
    this.getEntry();
  }

  ngOnInit() {}

  onSelect(item, list) {
    if (!item.selected) {
      const listItem = list.split(' ');
      if (listItem) {
        this[listItem[0]][listItem[1]].map((listItem) =>
          item.unique_name === listItem.unique_name
            ? (listItem.selected = !listItem.selected)
            : (listItem.selected = false)
        );
        this.setupResult();
      }
    }
  }

  setupResult() {
    const loanTerm = this.loanTerm?.loan_term_options?.find(
      (item) => item.selected
    ).unique_name;
    const downPayment = this.downPayment?.down_payment_options?.find(
      (item) => item.selected
    ).unique_name;
    const key = `${loanTerm} ${downPayment}`;
    const original = this.formatNumber(this.logicMap.initial_cost);
    this.finalValue = this.logicMap.map.find(
      (item) => item.unique_names_combination === key
    ).value;
    if (this.finalValue) {
      const newVal = this.formatNumber(this.finalValue);
      this.percentageIncrease = ((newVal - original) / original) * 100;
    }
  }

  formatNumber(value: string): number {
    return parseFloat(value.replace(/,/g, ''));
  }

  getEntry() {
    this.contentstack
      .getEntryWithQuery(
        'dce_page',
        { key: 'url', value: '/financing-education' },
        [],
        []
      )
      .then(
        (entry) => {
          this.pageContent = entry[0][0]?.page_components;
          const financingEducation =
            this.pageContent[1]?.dce_financing_education;
          this.loanTerm = financingEducation?.loan_term;
          this.downPayment = financingEducation?.down_payment;
          this.logicMap = financingEducation?.logic_map;
          this.setupResult();
        },
        (err) => {}
      );
  }

  onClickBack = (): Observable<any> => {
    return of(true);
  };
}
