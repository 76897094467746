import { Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Router } from "@angular/router";

import { ContentstackModule } from "src/modules/contentstack/contentstack.module";
import { ContentstackQueryService } from "src/app/services/cs.query.service";
import { MainLayoutComponent } from 'src/app/shared/components/main-layout/main-layout.component';
import { SelectionItemComponent } from 'src/app/shared/components/selection-item/selection-item.component';
import { Option } from '../app.model';
import { Observable, of } from 'rxjs';

@Component({
  selector: "app-energy-efficiency",
  standalone: true,
  imports: [
    CommonModule,
    ContentstackModule,
    MainLayoutComponent,
    SelectionItemComponent
  ],
  providers: [ContentstackQueryService],
  templateUrl: "./energy-efficiency.component.html",
  styleUrl: "./energy-efficiency.component.scss",
})
export class EnergyEfficiencyComponent implements OnInit {
  pageContent: any = [];
  glassOptions: {
    title: string,
    glass_options_list?: Option[]
  };
  gasOptions: {
    title: string,
    gas_options_list?: Option[]
  };
  suggestedProducts;
  logicMap;
  uValue = {
    image: '',
    value: ''
  }

  constructor(
    private contentstack: ContentstackQueryService,
    private router: Router
  ) {
    this.getEntry();
  }

  ngOnInit() {}

  onSelect(item: Option, list){
    if(!item.selected){
      const listItem = list.split(' ');
      if(listItem){
        this[listItem[0]][listItem[1]].map(listItem => item.unique_name === listItem.unique_name ? listItem.selected = !listItem.selected : listItem.selected = false)
        this.setupUValue();
      }
    }
  }

  setupUValue(){
    const glassOption = this.glassOptions?.glass_options_list?.find(item => item.selected).unique_name;
    const gas = this.gasOptions?.gas_options_list.find(item => item.selected).unique_name;
    const key = `${glassOption} ${gas}`;

    const obj = this.logicMap.find(item => item.unique_names_combination === key);
    if(obj){
      this.uValue.image = obj.image.url;
      this.uValue.value = obj.uvalue;
    }
  }

  getEntry() {
    this.contentstack
      .getEntryWithQuery(
        'dce_page',
        { key: 'url', value: "/energy-efficiency" },
        [],
        []
      )
      .then(
        (entry) => {
          this.pageContent = entry[0][0]?.page_components;
          this.glassOptions = this.pageContent[1]?.dce_energy_efficiency?.glass_options;
          this.gasOptions = this.pageContent[1]?.dce_energy_efficiency?.gas_options;
          this.suggestedProducts = this.pageContent[1]?.dce_energy_efficiency?.suggested_products;
          this.logicMap = this.pageContent[1]?.dce_energy_efficiency?.logic_map;
          this.setupUValue();
        },
        (err) => {}
      );
  }

  onClickBack = (): Observable<any> => {
    return of(true);
  };

  openInsideSalesScreen(){

  }

}
