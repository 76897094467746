import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ContentstackQueryService } from 'src/app/services/cs.query.service';
import { ContentstackModule } from 'src/modules/contentstack/contentstack.module';
import { getAbsoluteUrl } from '../../utils/utils';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, ContentstackModule],
  providers: [ContentstackQueryService],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  headerMap;
  isMobileNavShown = false;
  screenWidth = window.innerWidth;
  icons: { [key: string]: SafeHtml };
  isScrolled = false;

  constructor(
    private contentstack: ContentstackQueryService,
    private sanitizer: DomSanitizer
  ){
    this.icons = {
      MapPin: this.sanitizer.bypassSecurityTrustHtml(`<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" role="img" position="left" class="shared_icon__4APFr " aria-hidden="true" style="color:var(--icon-color, currentColor);width:var(--icon-width, var(--icon-size, 16px));height:var(--icon-height, var(--icon-size, 16px))"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 1 1 18 0"></path><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6"></path></svg>`),
      HelpCircle: this.sanitizer.bypassSecurityTrustHtml(`<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" role="img" position="left" class="shared_icon__4APFr " aria-hidden="true" style="color:var(--icon-color, currentColor);width:var(--icon-width, var(--icon-size, 16px));height:var(--icon-height, var(--icon-size, 16px))"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3m.08 4h.01M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10"></path></svg>`),
    };
  }
  ngOnInit(){
    this.contentstack.getEntry('header', ['cta_button_reference'], []).then(
      (entry) => {
        this.headerMap = entry[0][0];
      },
      (err) => {}
    );
  }

  modifyUrl(link){
    return getAbsoluteUrl(link);
  }

  toggleMobileNavigation(){
    this.isMobileNavShown = !this.isMobileNavShown;
    if(this.isMobileNavShown) this.headerMap.nav_items.forEach((item, index) => item.isOpen = index === 0 ? true : false);
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event): void {
    if (!(event.target as HTMLElement).closest('li') && this.screenWidth > 767) {
      this.headerMap.nav_items.forEach(item => item.isOpen = false);
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.updateScreenWidth();
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollPosition = window.scrollY;
    this.isScrolled = scrollPosition > 0;
  }

  private updateScreenWidth(): void {
    this.screenWidth = window.innerWidth;
  }
}
