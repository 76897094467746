import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContentstackModule } from 'src/modules/contentstack/contentstack.module';
import { ContentstackQueryService } from 'src/app/services/cs.query.service';
import { MainLayoutComponent } from 'src/app/shared/components/main-layout/main-layout.component';
import { BlogPostCardComponent } from 'src/app/shared/components/blog-post-card/blog-post-card.component';
import { Observable, Subscription, of } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { FormData, Topic } from 'src/app/services/data.model';

@Component({
  selector: 'app-explore-resources',
  standalone: true,
  imports: [
    CommonModule,
    ContentstackModule,
    MainLayoutComponent,
    BlogPostCardComponent,
  ],
  providers: [ContentstackQueryService],
  templateUrl: './explore-resources.component.html',
  styleUrl: './explore-resources.component.scss',
})
export class ExploreResourcesComponent implements OnInit, OnDestroy {
  pageContent: any = [];
  blogPosts: Topic[];
  sub: Subscription;
  constructor(
    private contentstack: ContentstackQueryService,
    private dataService: DataService
  ) {
    this.getEntry();
  }

  ngOnInit() {}

  getEntry() {
    this.contentstack
      .getEntryWithQuery(
        'dce_page',
        { key: 'url', value: '/explore-resources' },
        [],
        []
      )
      .then(
        (entry) => {
          this.pageContent = entry[0][0]?.page_components;
          if (this.dataService.id) {
            this.sub = this.dataService
              .getStepsData(this.dataService.id)
              .subscribe((data) => {
                this.setupBlogPosts(data);
              });
          }
        },
        (err) => {}
      );
  }

  setupBlogPosts(data: FormData): void {
    const sessionData = JSON.parse(data.sessionData);
    const selectedTopics = sessionData.topics;
    const list = this.pageContent[1]?.dce_blog_posts?.blog_posts_list;
    if (selectedTopics.length === 0) {
      this.blogPosts = this.getRandomItems(list, 3);
    } else {
      const selectedItems = list.filter((item) =>
        selectedTopics.some((topic) => topic.unique_name === item.unique_name)
      );
      const remainingItems = list.filter(
        (item) =>
          !selectedTopics.some(
            (topic) => topic.unique_name === item.unique_name
          )
      );
      const additionalItems = this.getRandomItems(
        remainingItems,
        3 - selectedItems.length
      );

      this.blogPosts = [...selectedItems, ...additionalItems];
    }
  }

  getRandomItems(array: Topic[], count: number): Topic[] {
    const shuffledArray = [...array].sort(() => Math.random() - 0.5);
    return shuffledArray.slice(0, count);
  }

  onClickBack = (): Observable<any> => {
    return of(true);
  };

  ngOnDestroy(): void {
    if (this.sub) this.sub.unsubscribe();
  }
}
