<div class="card">
  <div class="image">
    <img [src]="card.image.url" alt="">
  </div>
  <div class="description">
    <strong class="title">{{card.title}}</strong>
    <div class="cs-inner-html-16 mb-24" [innerHtml]="card.text"></div>
    <div class="read-more" *ngIf="card.read_more_button">
      <app-button
        [text]="card.read_more_button.text"
        type="readMore"
        rightIconSrc="/assets/icons/arrow-right-black.svg"
        [handleClick]="onButtonClick"
      />
    </div>
  </div>
</div>