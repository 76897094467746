<app-main-layout [clickBack]="onClickBack" [disableWatchingRoutes]="true">
  <div class="main-heading">
    <h2 [innerHtml]="pageContent[0]?.dce_page_introduction?.title"></h2>
    <div
      [innerHtml]="pageContent[0]?.dce_page_introduction?.text"
      class="cs-inner-html-16"
    ></div>
  </div>
  <div class="chart">
    <svg viewBox="0 0 36 36" class="circular-chart">
      <path class="circle-bg"
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <path class="circle"
        *ngIf="percentageIncrease"
        [attr.stroke-dasharray]="percentageIncrease + ', 100'"
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      />
    </svg>
    <div *ngIf="finalValue" class="sum"><span>{{'$'+ finalValue}}</span></div>
  </div>
  <div class="chart-description">
    <div class="item">
      <span>Initial cost</span>
      <i class="cost"></i>
    </div>
    <div class="item">
      <span>Interest</span>
      <i class="interest"></i>
    </div>
  </div>
  <div class="financing-info">
    <div class="financing-info-item">
      <div class="column">
        <strong class="title">{{loanTerm?.title}}</strong>
        <div
          [innerHtml]="loanTerm?.text"
          class="cs-inner-html-16"
        ></div>
      </div>
      <div class="column">
        <div class="names-list">
          <div
            *ngFor="let item of loanTerm?.loan_term_options"
            class="name"
            (click)="onSelect(item, 'loanTerm loan_term_options')"
            [ngClass]="{ selected: item.selected }"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="financing-info-item">
      <div class="column">
        <strong class="title">{{downPayment?.title}}</strong>
        <div
          [innerHtml]="downPayment?.text"
          class="cs-inner-html-16"
        ></div>
      </div>
      <div class="column">
        <div class="names-list">
          <div
            *ngFor="let item of downPayment?.down_payment_options"
            class="name"
            (click)="onSelect(item, 'downPayment down_payment_options')"
            [ngClass]="{ selected: item.selected }"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</app-main-layout>