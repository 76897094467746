<app-main-layout
  [clickNext]="onClickNext"
  [clickBack]="onClickBack"
  [activateNextButton$]="nextButtonState.activateNext$"
>
  <form class="form" [formGroup]="sourceForm" *ngIf="sourceForm">
    <div class="source" *ngIf="pageContent">
      <h3
        [innerHtml]="
          pageContent[0].dce_source_information.title
            | replaceUsername : username
        "
      ></h3>
      <div class="names-list">
        <div
          *ngFor="let item of sources"
          class="name"
          (click)="onSelect(item)"
          [ngClass]="{ selected: item.unique_name === selected }"
        >
          {{ item.title }}
        </div>
      </div>
      <app-form-group
        class="other"
        *ngIf="selected === otherSourceId"
        label="Other"
        [required]="true"
        id="other"
        [counter]="otherTextfieldMaxLength"
        formControlName="other"
      >
      </app-form-group>
    </div>
  </form>
</app-main-layout>
