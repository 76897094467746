<app-main-layout>
  <div class="main-heading large">
    <h2 [innerHtml]="pageContent[0]?.dce_page_introduction?.title | replaceUsername:name"></h2>
    <div
      [innerHtml]="pageContent[0]?.dce_page_introduction?.text"
      class="cs-inner-html-18"
    ></div>
  </div>
  <div class="blog-posts" *ngIf="blogPosts">
    <h3 class="h5-m">{{blogPosts.title}}</h3>
    <div class="blog-posts-frame">
      <app-blog-post-card *ngFor="let card of blogPosts.blog_posts_list" [card]="card" />
    </div>
  </div>
</app-main-layout>