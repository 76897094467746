<div class="page-wrapper">
  <div class="back-btn-wrapper">
    <app-button
      text="Back"
      type="flat"
      class="back-btn"
      leftIconSrc="/assets/icons/arrow-left.svg"
      leftIconSrc="/assets/icons/arrow-left.svg"
      [handleClick]="onClickBack"
    />
  </div>
  <div class="content-wrapper">
    <div class="left-column" *ngIf="pageData && pageData[0]">
      <h2 class="title">
        {{ pageData[0].process_info.title }}
      </h2>
      <div class="steps-wrapper">
        <app-process-info-card
          *ngFor="let step of pageData[0]?.process_info.process_steps"
          [step]="step"
        ></app-process-info-card>
      </div>
    </div>
    <div
      class="right-column"
      *ngIf="pageData && pageData[1] && !isRequestSubmitted"
    >
      <h2 class="title">{{ pageData[1].dce_details_form_data.title }}</h2>
      <form class="form-wrapper" [formGroup]="userForm" (ngSubmit)="onSubmit()">
        <app-form-group
          label="First Name"
          [required]="true"
          id="firstName"
          formControlName="firstName"
        >
        </app-form-group>
        <app-form-group
          label="Last Name"
          [required]="true"
          id="lastName"
          formControlName="lastName"
        >
        </app-form-group>
        <app-form-group
          label="Phone Number"
          [required]="true"
          id="phoneNumber"
          formControlName="phoneNumber"
          type="phone"
        >
        </app-form-group>
        <app-form-group
          label="Email"
          [required]="true"
          id="email"
          formControlName="email"
          type="email"
        >
        </app-form-group>
        <app-form-group
          label="Zipcode"
          [required]="true"
          id="zipcode"
          formControlName="zipcode"
          type="zip"
        >
        </app-form-group>
        <app-button
          [text]="pageData[1]?.dce_details_form_data.submit_button_text"
          type="filled"
          [disabled]="!isReadyToSubmit"
          class="submit-btn"
          [handleClick]="onSubmit"
        />
      </form>
    </div>
    <div
      class="right-column"
      *ngIf="pageData && pageData[1] && isRequestSubmitted"
    >
      <div class="msg-box">
        <div class="image-block">
          <img
            [src]="
              pageData[1]?.dce_details_form_data?.success_message_info?.image
                ?.url
            "
            alt="mail sent img"
          />
        </div>
        <div class="text-block">
          <h4 class="title">
            {{
              pageData[1]?.dce_details_form_data?.success_message_info?.title
            }}
          </h4>
          <p class="description">
            {{
              pageData[1]?.dce_details_form_data?.success_message_info
                ?.description
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
