import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

import { ContentstackModule } from 'src/modules/contentstack/contentstack.module';
import { ContentstackQueryService } from 'src/app/services/cs.query.service';
import { MainLayoutComponent } from 'src/app/shared/components/main-layout/main-layout.component';
import { SelectionItemComponent } from 'src/app/shared/components/selection-item/selection-item.component';
import { FormGroupComponent } from 'src/app/shared/components/form-group/form-group.component';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Observable, Subscription, map, of } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { WindowIssues, FormData } from 'src/app/services/data.model';
import { NAVIGATION_MAP, PAGES } from 'src/app/shared/constants/navigation-map';
import { NextButtonStateService } from 'src/app/services/next-button-state.service';

@Component({
  selector: 'app-window-issues',
  standalone: true,
  imports: [
    CommonModule,
    ContentstackModule,
    MainLayoutComponent,
    SelectionItemComponent,
    FormGroupComponent,
    ReactiveFormsModule,
  ],
  providers: [ContentstackQueryService],
  templateUrl: './window-issues.component.html',
  styleUrl: './window-issues.component.scss',
})
export class WindowIssuesComponent implements OnInit, OnDestroy {
  pageContent: any = [];
  selectionList: any[] = [];
  otherTextfieldMaxLength = 125;
  showOtherBlock = false;
  otherIssueForm: FormGroup;
  sub: Subscription;
  formChangesSub: Subscription;
  previouslyFilledData: {
    windowReasonEnum: number[];
    windowReasonOtherText: string;
  };
  otherIssueId: number = 876090099;

  constructor(
    private contentstack: ContentstackQueryService,
    private router: Router,
    private fb: FormBuilder,
    private dataService: DataService,
    public nextButtonState: NextButtonStateService
  ) {
    this.getEntry();
  }

  ngOnInit() {}

  getEntry() {
    this.contentstack
      .getEntryWithQuery(
        'dce_page',
        { key: 'url', value: '/window-issues' },
        [],
        []
      )
      .then(
        (entry) => {
          this.pageContent = entry[0][0]?.page_components;
          this.selectionList =
            this.pageContent[1]?.dce_window_issues?.window_issues;
          this.setupElements();
          if (this.dataService.id) {
            this.sub = this.dataService
              .getStepsData(this.dataService.id)
              .subscribe((data) => {
                this.prepopulateStepData(data);
              });
          }
        },
        (err) => {}
      );
  }

  prepopulateStepData(data: FormData) {
    this.subscribeToFormChanges();
    if (data?.appointmentRequest?.project?.windowReasonEnum) {
      this.previouslyFilledData = {
        windowReasonEnum: data.appointmentRequest.project.windowReasonEnum,
        windowReasonOtherText:
          data.appointmentRequest.project.windowReasonOtherText,
      };
      this.updateFormValues();
    }
  }

  updateFormValues() {
    this.selectionList = this.selectionList.map((item) => {
      const found = this.previouslyFilledData.windowReasonEnum.find(
        (prefilledItem) => item.unique_name === prefilledItem.toString()
      );
      return found ? { ...item, selected: true } : item;
    });
    const otherIssue = this.previouslyFilledData.windowReasonEnum.filter(
      (item) => item === this.otherIssueId
    );
    if (otherIssue?.length) {
      this.showOtherBlock = true;
      this.otherIssueForm.patchValue({
        issue: this.previouslyFilledData.windowReasonOtherText,
      });
      this.addOtherControlValidation(true);
    }
    this.checkFormValidity();
  }

  subscribeToFormChanges(): void {
    this.formChangesSub = this.otherIssueForm.valueChanges.subscribe(
      (value) => {
        this.checkFormValidity();
      }
    );
  }

  checkFormValidity() {
    setTimeout((_) => {
      this.nextButtonState.emitActivateNext(!!this.otherIssueForm.valid);
    });
  }

  setupElements(): void {
    this.selectionList = this.selectionList.map((item) => {
      return { ...item, selected: false };
    });
    this.otherIssueForm = this.fb.group({
      issue: [''],
    });
  }

  addOtherControlValidation(add: boolean) {
    const other = this.otherIssueForm.get('issue');
    if (add) {
      other.setValidators([
        Validators.required,
        Validators.maxLength(this.otherTextfieldMaxLength),
      ]);
    } else {
      other.clearValidators();
      other.updateValueAndValidity();
    }
  }

  selectItem(item) {
    item.selected = !item.selected;
    this.checkFormValidity();
  }

  switchOther(show, e?) {
    if (e) e.preventDefault();
    this.showOtherBlock = show;
    this.addOtherControlValidation(show);
    this.checkFormValidity();
    if (!show) {
      this.otherIssueForm.patchValue({
        issue: '',
      });
    }
  }

  onClickNext = (): Observable<any> => {
    return this.saveData(NAVIGATION_MAP[PAGES.WINDOW_COUNT].nextButton.route);
  };

  onClickBack = (): Observable<any> => {
    return this.otherIssueForm.valid
      ? this.saveData(NAVIGATION_MAP[PAGES.WINDOW_COUNT].backButton.route)
      : of(true);
  };

  saveData(nextPage): Observable<any> {
    const selected = this.selectionList.filter((item) => item.selected);
    let dataToStore = [];

    if (selected.length) {
      dataToStore = this.selectionList.filter((item) => item.selected);
    }
    if (this.otherIssueForm.get('issue').value.trim() !== '') {
      dataToStore.push({
        unique_name: this.otherIssueId,
        title: this.otherIssueForm.get('issue').value,
      });
    }

    const stepsData = { ...this.dataService.stepsData };
    stepsData.appointmentRequest.project.windowReasonEnum = dataToStore.map(
      (item) => Number(item.unique_name)
    );
    stepsData.appointmentRequest.project.windowReasonOtherText =
      this.otherIssueForm.get('issue').value;
    const sessionData = JSON.parse(stepsData.sessionData);
    sessionData.nextPage = nextPage;
    stepsData.sessionData = JSON.stringify(sessionData);
    console.log('stepsData', stepsData);

    return this.dataService.saveStepsData(stepsData).pipe(
      map((res) => {
        return true;
      })
    );
  }

  ngOnDestroy(): void {
    if (this.sub) this.sub.unsubscribe();
    if (this.formChangesSub) this.formChangesSub.unsubscribe();
  }
}
