<form
  class="form-group"
  [formGroup]="formGroup"
  [ngClass]="{
    error:
      formGroup.controls[formControlName].touched &&
      !formGroup.controls[formControlName].valid
  }"
>
  <label [attr.for]="id">{{ label }} <span *ngIf="required"> *</span></label>
  <div class="input-frame">
    <input
      [id]="id"
      [formControlName]="formControlName"
      [placeholder]="placeholder"
      [mask]="
        type === 'phone'
          ? phoneMask
          : type === 'zip'
          ? zipMask
          : type === 'counter'
          ? counterMask
          : null
      "
      [type]="
      type === 'phone'
        ? 'tel'
        : (type === 'zip' || type === 'counter')
        ? 'number'
        : type === 'email'
        ? 'email'
        : 'text'
      "
      *ngIf="!textarea"
      [ngClass]="{ 'centered': inputCentered }"
    />
    <textarea
      *ngIf="textarea"
      [name]="label"
      [placeholder]="placeholder"
      [id]="id"
      [formControlName]="formControlName"
      row="4"
    ></textarea>
    <div
      *ngIf="showIcons"
      class="icon-wrapper"
      [ngClass]="{ 'textarea-icon': textarea }"
    >
      <img
        *ngIf="
          formGroup.controls[formControlName].value?.trim() === '' &&
          !formGroup.controls[formControlName].touched
        "
        src="/assets/icons/cross-circle.svg"
        alt="No Value"
        class="empty"
      />
      <img
        *ngIf="
          formGroup.controls[formControlName].touched &&
          !formGroup.controls[formControlName].valid
        "
        src="/assets/icons/alert-circle.svg"
        alt="Invalid Value"
        class="invalid"
      />
      <img
        *ngIf="
          formGroup.controls[formControlName].value?.trim() !== '' &&
          formGroup.controls[formControlName].valid
        "
        src="/assets/icons/check-circle.svg"
        alt="Valid Value"
        class="valid"
      />
    </div>
  </div>
  <div class="error-msg">
    <div
      *ngIf="
        formGroup.controls[formControlName].invalid &&
        formGroup.controls[formControlName].touched
      "
    >
      <span *ngIf="formGroup.controls[formControlName].errors?.['required']">{{
        ERROR_MESSAGES.FIELD_REQUIRED
      }}</span>
      <span *ngIf="formGroup.controls[formControlName].errors?.['maxlength']"
        >Text length should be from 1 to
        {{
          formGroup.controls[formControlName].errors['maxlength'].requiredLength
        }}
        symbols.</span
      >
      <span
        *ngIf="type === 'phone' && formGroup.controls[formControlName].errors?.['mask']"
        >{{ ERROR_MESSAGES.INVALID_PHONE }}</span
      >
      <span
        *ngIf="type === 'phone' && formGroup.controls[formControlName].errors?.['forbiddenPhone']"
        >{{ ERROR_MESSAGES.FORBIDDEN_PHONE }}</span
      >
      <span
        *ngIf="type === 'email' && formGroup.controls[formControlName].errors?.['pattern']"
        >{{ ERROR_MESSAGES.INVALID_EMAIL }}</span
      >
      <span
        *ngIf="type === 'text' && formGroup.controls[formControlName].errors?.['pattern']"
        >{{ ERROR_MESSAGES.INVALID_CHARACTERS }}</span
      >
      <span
        *ngIf="type === 'zip' && formGroup.controls[formControlName].errors?.['mask']"
        >{{ ERROR_MESSAGES.INVALID_ZIPCODE }}</span
      >
      <span
        *ngIf="type === 'counter' && formGroup.controls[formControlName].errors?.['min']"
        >{{ errorMessages['min'] }}</span
      >
      <span
        class="composite-msg"
        *ngIf="type === 'counter' && formGroup.controls[formControlName].errors?.['max']"
        >{{ errorMessages['max'] }}</span
      >
    </div>
  </div>
  <span
    class="counter"
    *ngIf="counter"
    [ngClass]="{ 'textarea-counter': textarea }"
  >
    {{ formGroup.get(formControlName)?.value?.length || 0 }}/{{ counter }}
  </span>
</form>
