import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { ProgressBarConfigItem, ProgressBarState } from './progress-bar.model';
import { Subscription } from 'rxjs';
import { ProgressBarService } from './progress-bar.service';
import { ContentstackModule } from 'src/modules/contentstack/contentstack.module';
import { ContentstackQueryService } from 'src/app/services/cs.query.service';

@Component({
  selector: 'app-progress-bar',
  standalone: true,
  imports: [CommonModule, ContentstackModule],
  providers: [ContentstackQueryService],
  templateUrl: './progress-bar.component.html',
  styleUrl: './progress-bar.component.scss',
})
export class ProgressBarComponent implements OnInit {
  progressStatus!: ProgressBarState;
  progressBarSubscription!: Subscription;
  progressBarConfig = [];
  progressBarConfigSubscription!: Subscription;

  get progressBarLinesConfig() {
    return this.progressBarConfig.slice(0, -1);
  }
  isScrolled = false;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollPosition = window.scrollY;
    this.isScrolled = scrollPosition > 85;
  }
  constructor(
    private progressBarService: ProgressBarService,
    private contentstack: ContentstackQueryService
  ) {
    this.progressBarSubscription = this.progressBarService
      .getChangedProgressBarStatus()
      .subscribe((value: ProgressBarState) => {
        this.progressStatus = value;
        if (this.progressStatus) {
        }
      });

    this.progressBarConfigSubscription = this.progressBarService
      .getProgressBarConfig()
      .subscribe((value: ProgressBarConfigItem[]) => {
        this.progressBarConfig = value;
        if (!value.length) {
          this.contentstack.getEntry('progress_bar', [], []).then(
            (entry) => {
              this.progressBarService.changeProgressBarConfig(
                entry[0][0]?.steps
              );
              this.progressBarConfig = entry[0][0]?.steps;
            },
            (err) => {}
          );
        }
      });
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.progressBarSubscription.unsubscribe();
    this.progressBarConfigSubscription.unsubscribe();
  }
}
