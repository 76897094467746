import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, Subscription, map, of } from 'rxjs';

import { ContentstackModule } from 'src/modules/contentstack/contentstack.module';
import { ContentstackQueryService } from 'src/app/services/cs.query.service';
import { MainLayoutComponent } from 'src/app/shared/components/main-layout/main-layout.component';
import { CounterComponent } from 'src/app/shared/components/counter/counter.component';
import { DataService } from 'src/app/services/data.service';
import { FormData } from 'src/app/services/data.model';
import { NAVIGATION_MAP, PAGES } from 'src/app/shared/constants/navigation-map';
import { NextButtonStateService } from 'src/app/services/next-button-state.service';

@Component({
  selector: 'app-window-count',
  standalone: true,
  imports: [
    CommonModule,
    ContentstackModule,
    MainLayoutComponent,
    CounterComponent,
  ],
  providers: [ContentstackQueryService],
  templateUrl: './window-count.component.html',
  styleUrl: './window-count.component.scss',
})
export class WindowCountComponent implements OnInit, OnDestroy {
  pageContent: any = [];
  counterValue = 0;
  counterMinValue = 0;
  counterMaxValue = 50;
  counterStep = 1;
  counterInitialValue = 0;
  imagesMap = [];
  currentImage: string;
  valid = true;
  ranges = [
    { min: 0, max: 0, index: 0 },
    { min: 1, max: 1, index: 1 },
    { min: 2, max: 2, index: 2 },
    { min: 3, max: 3, index: 3 },
    { min: 4, max: 4, index: 4 },
    { min: 5, max: 5, index: 5 },
    { min: 6, max: 6, index: 6 },
    { min: 7, max: 7, index: 7 },
    { min: 8, max: 8, index: 8 },
    { min: 9, max: 9, index: 9 },
    { min: 10, max: 10, index: 10 },
    { min: 11, max: 11, index: 11 },
    { min: 12, max: 12, index: 12 },
    { min: 13, max: 50, index: 13 },
  ];
  sub: Subscription;

  constructor(
    private contentstack: ContentstackQueryService,
    private dataService: DataService,
    public nextButtonState: NextButtonStateService
  ) {
    this.getEntry();
  }

  ngOnInit() {}

  getEntry() {
    this.contentstack
      .getEntryWithQuery(
        'dce_page',
        { key: 'url', value: '/window-count' },
        [],
        []
      )
      .then(
        (entry) => {
          this.pageContent = entry[0][0]?.page_components;
          this.imagesMap = this.pageContent[2]?.dce_window_count.images_map;
          this.currentImage = this.switchImage(this.counterValue);
          if (this.dataService.id) {
            this.sub = this.dataService
              .getStepsData(this.dataService.id)
              .subscribe((data) => {
                this.prepopulateStepData(data);
              });
          }
        },
        (err) => {}
      );
  }

  prepopulateStepData(data: FormData) {
    if (data?.appointmentRequest?.project?.windowCount) {
      this.counterInitialValue = data.appointmentRequest.project.windowCount;
    }
    this.checkFormValidity();
  }

  checkFormValidity() {
    setTimeout((_) => {
      this.nextButtonState.emitActivateNext(this.valid);
    });
  }

  switchImage(value: number): string {
    const index = this.findIndexBasedOnValue(value);

    return this.imagesMap.find((item) => item.number === index)?.image.url;
  }

  onCounterValueChange({
    value,
    valid,
  }: {
    value: number;
    valid: boolean;
  }): void {
    this.counterValue = value;
    this.currentImage = this.switchImage(value);
    this.valid = valid;
    this.checkFormValidity();
  }

  findIndexBasedOnValue(value: number) {
    for (let range of this.ranges) {
      if (value >= range.min && value <= range.max) {
        return range.index;
      }
    }
    if (value < this.ranges[0].min) {
      return 3;
    }
    return 13;
  }

  onClickNext = (): Observable<any> => {
    return this.valid
      ? this.saveData(NAVIGATION_MAP[PAGES.WINDOW_COUNT].nextButton.route)
      : of(false);
  };

  onClickBack = (): Observable<any> => {
    return this.valid
      ? this.saveData(NAVIGATION_MAP[PAGES.WINDOW_COUNT].backButton.route)
      : of(true);
  };

  saveData(nextPage): Observable<any> {
    const stepsData = { ...this.dataService.stepsData };
    stepsData.appointmentRequest.project.windowCount = this.counterValue;
    const sessionData = JSON.parse(stepsData.sessionData);
    sessionData.nextPage = nextPage;
    stepsData.sessionData = JSON.stringify(sessionData);
    return this.dataService.saveStepsData(stepsData).pipe(
      map((res) => {
        return true;
      })
    );
  }

  ngOnDestroy(): void {
    if (this.sub) this.sub.unsubscribe();
  }
}
