<div class="counter-container" [formGroup]="formGroup">
  <button class="button toggle-less" (click)="onClickLess()">
    <img src="assets/icons/minus.svg" alt="minus-btn" />
  </button>
  <div class="counter-input-field">
    <app-form-group
      [label]="label"
      id="count"
      formControlName="count"
      type="counter"
      [showIcons]="false"
      [errorMessages]="errorMessages[type]"
      [inputCentered]="true"
    ></app-form-group>
  </div>
  <button class="button toggle-more" (click)="onClickMore()">
    <img src="assets/icons/plus.svg" alt="plus-btn" />
  </button>
</div>
