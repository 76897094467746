import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { map, Observable, of, Subscription } from 'rxjs';
import { ContentstackQueryService } from 'src/app/services/cs.query.service';
import { FormData, MarketingAttribution } from 'src/app/services/data.model';
import { DataService } from 'src/app/services/data.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { NextButtonStateService } from 'src/app/services/next-button-state.service';
import { CheckboxComponent } from 'src/app/shared/components/checkbox/checkbox.component';
import { MainLayoutComponent } from 'src/app/shared/components/main-layout/main-layout.component';
import { ContentstackModule } from 'src/modules/contentstack/contentstack.module';
import { ReplaceUsernamePipe } from 'src/app/shared/pipes/replace-username.pipe';
import { FormGroupComponent } from 'src/app/shared/components/form-group/form-group.component';
import { NAVIGATION_MAP, PAGES } from 'src/app/shared/constants/navigation-map';

@Component({
  selector: 'app-source-type',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ContentstackModule,
    MainLayoutComponent,
    FormGroupComponent,
    CheckboxComponent,
    ReplaceUsernamePipe,
  ],
  providers: [ContentstackQueryService],
  templateUrl: './source-type.component.html',
  styleUrl: './source-type.component.scss',
})
export class SourceTypeComponent implements OnInit, OnDestroy {
  pageContent: any;
  sub: Subscription;
  formChangesSub: Subscription;
  availabilitySub: Subscription;
  validationSub: Subscription;
  sourceForm: FormGroup;
  selected: string = '';
  previouslyFilledData: {
    marketingAttribution: MarketingAttribution;
  };
  sources: [
    {
      unique_name: string;
      title: string;
    }
  ];
  otherTextfieldMaxLength = 125;
  otherSourceId: string = '876090099';
  username: string;
  nextStep = NAVIGATION_MAP[PAGES.SOURCE_TYPE].nextButton.route;
  otherProperties = {
    zipcodeValid: false,
    zipcodeGuid: '',
    marketSegmentGuid: '',
    ownershipValidated: false,
  };
  insideSalesReasonEnum: number;
  nonPrimaryResidenceReasons = [876090001, 876090002, 876090003];
  otherPropertyType = 876090099;

  constructor(
    private contentstack: ContentstackQueryService,
    private fb: FormBuilder,
    private dataService: DataService,
    public nextButtonState: NextButtonStateService,
    private navigationService: NavigationService
  ) {
    this.getEntry();
  }

  ngOnInit(): void {
    this.buildForm();
  }

  getEntry() {
    this.contentstack
      .getEntryWithQuery(
        'dce_page',
        { key: 'url', value: '/dce-source' },
        [],
        []
      )
      .then(
        (entry) => {
          this.pageContent = entry[0][0]?.page_components;
          this.setupSourcesList();
          if (this.dataService.id) {
            this.sub = this.dataService
              .getStepsData(this.dataService.id)
              .subscribe((data) => {
                this.prepopulateStepData(data);
                if (
                  this.checkPropertyType(data) ||
                  this.checkWindowCount(data)
                ) {
                  this.nextStep = PAGES.CALL_CENTER;
                }
                this.availabilitySub = this.dataService
                  .serviceAvailability()
                  .subscribe((response) => {
                    this.otherProperties.marketSegmentGuid =
                      response.marketSegmentId || '';
                    this.otherProperties.zipcodeGuid = response.zipcodeId || '';
                    this.otherProperties.zipcodeValid =
                      response.isServiceAvailable;
                    if (!response.isServiceAvailable) {
                      this.nextStep = PAGES.DO_NOT_SERVICE_AREA;
                    } else {
                      this.validationSub = this.dataService
                        .propertyValidation()
                        .subscribe((response) => {
                          this.otherProperties.ownershipValidated =
                            response.isPropertyOwnershipValidated;
                        });
                    }
                  });
              });
          }
        },
        (err) => {}
      );
  }

  checkWindowCount(data: FormData): boolean {
    if (data?.appointmentRequest?.project?.windowCount < 3) {
      this.insideSalesReasonEnum = 864630002;
      return true;
    }
    return false;
  }

  checkPropertyType(data: FormData): boolean {
    const property = data?.appointmentRequest?.property;

    if (property?.propertyType) {
      const other = this.otherPropertyType === property?.propertyType;
      const nonPrimary = this.nonPrimaryResidenceReasons.includes(
        property?.propertyType
      );

      if (other) this.insideSalesReasonEnum = 864630013;
      if (nonPrimary) this.insideSalesReasonEnum = 864630017;

      if (other || nonPrimary) {
        return true;
      }
    }
    return false;
  }

  buildForm() {
    this.sourceForm = this.fb.group({
      selected: ['', Validators.required],
      other: '',
    });
    this.subscribeToFormChanges();
  }

  subscribeToFormChanges(): void {
    this.formChangesSub = this.sourceForm.valueChanges.subscribe((value) => {
      this.checkFormValidity();
    });
  }

  checkFormValidity() {
    setTimeout((_) => {
      this.nextButtonState.emitActivateNext(this.sourceForm.valid);
    });
  }

  prepopulateStepData(data: FormData) {
    const sessionData = JSON.parse(data.sessionData);
    if (
      data?.appointmentRequest?.customer &&
      data?.appointmentRequest?.marketingAttribution
    ) {
      this.username = data?.appointmentRequest?.customer.firstName;
      this.previouslyFilledData = {
        marketingAttribution: data.appointmentRequest.marketingAttribution,
      };
    }
    this.updateFormValues();
  }

  updateFormValues() {
    this.sourceForm.patchValue({
      selected:
        this.previouslyFilledData?.marketingAttribution?.sourceTypeEnum || '',
      other:
        this.previouslyFilledData?.marketingAttribution?.sourceTypeOtherText ||
        '',
    });
    this.selected =
      this.previouslyFilledData?.marketingAttribution?.sourceTypeEnum.toString() ||
      '';
    this.addOtherControlValidation(true);
  }

  addOtherControlValidation(add: boolean) {
    const other = this.sourceForm.get('other');
    if (add) {
      other.setValidators([
        Validators.required,
        Validators.maxLength(this.otherTextfieldMaxLength),
      ]);
    } else {
      other.clearValidators();
      other.updateValueAndValidity();
    }
  }

  setupSourcesList() {
    const sources = this.pageContent[0]?.dce_source_information?.sources;
    const other = sources?.find(
      (item) => item.unique_name === this.otherSourceId
    );
    this.sources = sources
      ?.filter((item) => item.unique_name !== this.otherSourceId)
      .sort(() => Math.random() - 0.5);
    if (other) {
      this.sources.push(other);
    }
  }

  onSelect(item: any): void {
    this.selected = item.unique_name;
    this.sourceForm.patchValue({ selected: this.selected });
    if (this.selected !== this.otherSourceId) {
      this.sourceForm.patchValue({ other: '' });
    }
    this.addOtherControlValidation(this.selected === this.otherSourceId);
  }

  onClickNext = (): Observable<any> => {
    if (this.sourceForm.valid) {
      return this.saveData();
    }
    this.sourceForm.markAllAsTouched();
    return of(false);
  };

  onClickBack = (): Observable<any> => {
    return this.sourceForm.valid
      ? this.saveData(NAVIGATION_MAP[PAGES.SOURCE_TYPE].backButton.route)
      : of(true);
  };

  saveData(nextPage?: string): Observable<any> {
    const stepsData = { ...this.dataService.stepsData };
    stepsData.appointmentRequest.marketingAttribution.sourceTypeEnum = Number(
      this.sourceForm.value.selected
    );
    stepsData.appointmentRequest.marketingAttribution.sourceTypeOtherText =
      this.sourceForm.value.other;
    stepsData.appointmentRequest.property.marketSegmentGuid =
      this.otherProperties.marketSegmentGuid;
    stepsData.appointmentRequest.property.zipcodeGuid =
      this.otherProperties.zipcodeGuid;
    stepsData.appointmentRequest.property.zipcodeValid =
      this.otherProperties.zipcodeValid;
    stepsData.appointmentRequest.property.ownership.ownershipValidated =
      this.otherProperties.ownershipValidated;
    if (this.nextStep === PAGES.CALL_CENTER) {
      stepsData.appointmentRequest.insideSales = true;
      stepsData.appointmentRequest.insideSalesReasonEnum = this.insideSalesReasonEnum;
      stepsData.status = 'InsideSales';
    }
    const sessionData = JSON.parse(stepsData.sessionData);
    sessionData.nextPage = nextPage || this.nextStep;
    stepsData.sessionData = JSON.stringify(sessionData);

    return this.dataService.saveStepsData(stepsData).pipe(
      map((res) => {
        this.navigationService.navigateToPath(nextPage || this.nextStep, {
          id: res.id,
        });
        return false;
      })
    );
  }

  ngOnDestroy(): void {
    if (this.sub) this.sub.unsubscribe();
    if (this.formChangesSub) this.formChangesSub.unsubscribe();
    if (this.availabilitySub) this.availabilitySub.unsubscribe();
    if (this.validationSub) this.validationSub.unsubscribe();
  }
}
