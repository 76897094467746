<app-main-layout
  [clickNext]="onClickNext"
  [clickBack]="onClickBack"
  [backRoute]="getBackRoute"
  [activateNextButton$]="nextButtonState.activateNext$"
>
  <div class="name-wrapper" *ngIf="pageContent" [ngClass]="{'embed': isEmbedded}">
    <div class="main-heading modify-text">
      <h2 [innerHtml]="pageContent[0]?.dce_page_introduction?.title"></h2>
      <div
          *ngIf="isEmbedded"
          [innerHtml]="pageContentContact[0]?.dce_page_introduction?.text"
          class="cs-inner-html-16"
        ></div>
    </div>
    <form
      class="form-wrapper"
      [formGroup]="personalInfoForm"
      *ngIf="personalInfoForm"
    >
      <app-form-group
        label="First Name"
        id="firstName"
        [required]="true"
        formControlName="firstName"
      >
      </app-form-group>
      <app-form-group
        [required]="true"
        label="Last Name"
        id="lastName"
        formControlName="lastName"
      >
      </app-form-group>
      <div
        *ngIf="
          pageContentContact &&
         ((personalInfoForm.get('firstName').valid &&
          personalInfoForm.get('lastName').valid) || isEmbedded)
        "
      >
        <div class="main-heading" *ngIf="!isEmbedded">
          <h2
            [innerHtml]="
              pageContentContact[0]?.dce_page_introduction?.title
                | replaceUsername : this.personalInfoForm.get('firstName').value
            "
          ></h2>
          <div
            [innerHtml]="pageContentContact[0]?.dce_page_introduction?.text"
            class="cs-inner-html-16"
          ></div>
        </div>
        <div class="contact-form">
          <app-form-group
            label="Primary email"
            [required]="true"
            id="primaryEmail"
            formControlName="primaryEmail"
            type="email"
          ></app-form-group>
          <app-form-group
            label="Primary phone number"
            [required]="true"
            id="primaryPhone"
            formControlName="primaryPhone"
            type="phone"
          ></app-form-group>
          <app-checkbox
            formControlName="contactPreference"
            [label]="
              pageContentContact[1]?.dce_contact_form_data?.agreement_text
            "
            [labelHTML]="
              pageContentContact[1]?.dce_contact_form_data?.agreement_text
            "
          ></app-checkbox>
        </div>
      </div>
    </form>
  </div>
</app-main-layout>
