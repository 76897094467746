<app-main-layout>
  <div class="ckeckmark">
    <img src="assets/icons/checkmark.svg" alt="">
  </div>
  <div class="main-heading result-heading">
    <h2 *ngIf="title.show" class="center" [ngClass]="{'no-date': !title.hasDate}" [innerHtml]="pageContent[0]?.dce_page_introduction?.title"></h2>
    <div
      [innerHtml]="pageContent[0]?.dce_page_introduction?.text"
      class="cs-inner-html-16 center"
    ></div>
  </div>
  <div class="whats-next">
    <h2>{{whatsNext?.title}}</h2>
    <div
      [innerHtml]="whatsNext?.text"
      class="cs-inner-html-18 mb-24"
    ></div>
    <app-process-info-card
      *ngFor="let step of whatsNext?.process_steps"
      [step]="step"
    ></app-process-info-card>
    <div class="button-holder">
      <app-button
        [text]="whatsNext?.button_text"
        type="filled"
        rightIconSrc="/assets/icons/arrow-right-black.svg"
        class="btn"
        [handleClick]="onClickNext"
      />
    </div>
  </div>
</app-main-layout>