import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { MainLayoutComponent } from 'src/app/shared/components/main-layout/main-layout.component';
import { ContentstackModule } from 'src/modules/contentstack/contentstack.module';
import { ContentstackQueryService } from 'src/app/services/cs.query.service';
import { DataService } from 'src/app/services/data.service';
import { FormGroupComponent } from 'src/app/shared/components/form-group/form-group.component';
import { ReplaceUsernamePipe } from 'src/app/shared/pipes/replace-username.pipe';
import { Observable, Subscription, map, of } from 'rxjs';
import { FormData, Customer } from 'src/app/services/data.model';
import { NextButtonStateService } from 'src/app/services/next-button-state.service';
import { NAVIGATION_MAP, PAGES } from 'src/app/shared/constants/navigation-map';
import { NavigationService } from 'src/app/services/navigation.service';
import { VALIDATION_PATTERNS } from 'src/app/shared/constants/text-field';
import {
  FORBIDDEN_PHONES,
  forbiddenPhoneValidator,
} from 'src/app/shared/constants/phone-validator';
import { CheckboxComponent } from 'src/app/shared/components/checkbox/checkbox.component';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { marketingAttributes } from 'src/app/shared/constants/marketing-params';

@Component({
  selector: 'app-name',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ContentstackModule,
    MainLayoutComponent,
    FormGroupComponent,
    ReplaceUsernamePipe,
    CheckboxComponent,
  ],
  providers: [ContentstackQueryService],
  templateUrl: './name.component.html',
  styleUrl: './name.component.scss',
})
export class NameComponent implements OnInit, OnDestroy {
  pageContent: any;
  pageContentContact: any;
  personalInfoForm: FormGroup;
  otherTextfieldMaxLength = 125;
  sub: Subscription;
  formChangesSub: Subscription;
  previousPage: string;
  previouslyFilledData: {
    customer: Customer;
    marketingConsent: boolean;
  };
  routeSub: Subscription;
  paramsSub: Subscription;
  isEmbedded: boolean = false;
  marketingParams: any[];
  domainNames = {
    development: 'https://web-use-d365ce-digital-dv.azurewebsites.net/',
    production: 'https://appointment.windownation.com/'
  }

  constructor(
    private contentstack: ContentstackQueryService,
    private fb: FormBuilder,
    private dataService: DataService,
    public nextButtonState: NextButtonStateService,
    private navigationService: NavigationService,
    private route: ActivatedRoute
  ) {
    this.getEntry();
    this.getContactEntry();
  }

  ngOnInit(): void {
    this.buildForm();
    this.routeSub = this.route.data.subscribe((data) => {
      this.isEmbedded = data['embed'] || false;
      if (this.isEmbedded) {
        this.navigationService.setIsEmbedded(true);
      }
    });
    this.paramsSub = this.route.queryParams.subscribe((params) => {
      this.marketingParams = Object.keys(params)
        .filter((key) => marketingAttributes.includes(key))
        .map((key) => ({ parameter: key, value: params[key] }));
    });
  }

  getEntry() {
    this.contentstack
      .getEntryWithQuery('dce_page', { key: 'url', value: '/name' }, [], [])
      .then(
        (entry) => {
          this.pageContent = entry[0][0]?.page_components;
          if (this.dataService.id) {
            this.sub = this.dataService
              .getStepsData(this.dataService.id)
              .subscribe((data) => {
                this.prepopulateStepData(data);
              });
          } else {
            this.previousPage = localStorage.getItem('getStartedSelection');
          }
        },
        (err) => {}
      );
  }

  getContactEntry() {
    this.contentstack
      .getEntryWithQuery('dce_page', { key: 'url', value: '/contact' }, [], [])
      .then(
        (entry) => {
          this.pageContentContact = entry[0][0]?.page_components;
        },
        (err) => {}
      );
  }

  buildForm() {
    this.personalInfoForm = this.fb.group({
      firstName: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.pattern(VALIDATION_PATTERNS.text),
        ],
      ],
      lastName: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.pattern(VALIDATION_PATTERNS.text),
        ],
      ],
      primaryEmail: [
        '',
        [Validators.required, Validators.pattern(VALIDATION_PATTERNS.email)],
      ],
      primaryPhone: [
        '',
        [Validators.required, forbiddenPhoneValidator(FORBIDDEN_PHONES)],
      ],
      contactPreference: [true, [Validators.required, Validators.requiredTrue]],
    });
    this.subscribeToFormChanges();
  }

  subscribeToFormChanges(): void {
    this.formChangesSub = this.personalInfoForm.valueChanges.subscribe(
      (value) => {
        this.checkFormValidity();
      }
    );
  }

  checkFormValidity() {
    setTimeout((_) => {
      this.nextButtonState.emitActivateNext(this.personalInfoForm.valid);
    });
  }

  updateFormValues() {
    this.personalInfoForm.patchValue({
      firstName: this.previouslyFilledData?.customer?.firstName || '',
      lastName: this.previouslyFilledData?.customer?.lastName || '',
      primaryEmail: this.previouslyFilledData?.customer?.email || '',
      primaryPhone: this.previouslyFilledData?.customer?.phonePrimary || '',
      contactPreference: this.previouslyFilledData?.marketingConsent || '',
    });
  }

  prepopulateStepData(data: FormData) {
    const sessionData = JSON.parse(data.sessionData);
    this.previousPage =
      sessionData?.getStartedSelection ||
      localStorage.getItem('getStartedSelection');
    if (this.previousPage === 'name') {
      this.previousPage = '';
    }
    if (
      data?.appointmentRequest?.customer &&
      data?.appointmentRequest?.marketingAttribution
    ) {
      this.previouslyFilledData = {
        customer: data.appointmentRequest.customer,
        marketingConsent: this.previouslyFilledData?.marketingConsent || true,
      };
    }
    this.updateFormValues();
  }

  onClickNext = (): Observable<any> => {
    if (this.personalInfoForm.valid) {
      return this.saveData(NAVIGATION_MAP[PAGES.NAME].nextButton.route);
    }
    this.personalInfoForm.markAllAsTouched();
    return of(false);
  };

  onClickBack = (): Observable<any> => {
    if (this.previousPage === 'name') {
      this.previousPage = '';
    }
    return this.personalInfoForm.valid
      ? this.saveData(this.previousPage)
      : of(true);
  };

  saveData(nextPage): Observable<any> {
    const stepsData = { ...this.dataService.stepsData };
    stepsData.appointmentRequest.customer.firstName =
      this.personalInfoForm.value.firstName;
    stepsData.appointmentRequest.customer.lastName =
      this.personalInfoForm.value.lastName;
    stepsData.appointmentRequest.customer.email =
      this.personalInfoForm.value.primaryEmail;
    stepsData.appointmentRequest.customer.phonePrimary =
      this.personalInfoForm.value.primaryPhone;
    stepsData.appointmentRequest.marketingConsent =
      this.personalInfoForm.value.contactPreference;
    const sessionData = JSON.parse(stepsData.sessionData);
    sessionData.nextPage = nextPage;
    stepsData.sessionData = JSON.stringify(sessionData);

    return this.dataService.saveStepsData(stepsData).pipe(
      map((res) => {
        if(this.isEmbedded){
          let urlString = !!environment.production
            ? this.domainNames.production
            : this.domainNames.development;
          urlString = `${urlString}${nextPage}?id=${res.id}`;
          for (let index = 0; index < this.marketingParams.length; index++) {
            if (index === 0 && this.marketingParams[index].parameter) {
              urlString = `${urlString}?${this.marketingParams[index].parameter}=${this.marketingParams[index].value}`;
            } else if (this.marketingParams[index].parameter) {
              urlString = `${urlString}&${this.marketingParams[index].parameter}=${this.marketingParams[index].value}`;
            }
          }
          window.open(urlString);
          return of(false);
        } else {
          this.navigationService.navigateToPath(nextPage, { id: res.id });
        }
        return false;
      })
    );
  }

  getBackRoute = () => {
    return this.previousPage;
  };

  ngOnDestroy(): void {
    if (this.sub) this.sub.unsubscribe();
    if (this.formChangesSub) this.formChangesSub.unsubscribe();
    if (this.routeSub) this.routeSub.unsubscribe();
    if(this.paramsSub) this.paramsSub.unsubscribe();
  }
}
