import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-selection-item',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './selection-item.component.html',
  styleUrl: './selection-item.component.scss'
})
export class SelectionItemComponent {
  @Input() item: any;
  @Input() selected: boolean;
  @Input() cssClass: string = '';
  @Input() noBorder?: boolean;
  @Input() type?: 'single' | 'multiple' = 'multiple';
  @Output() onItemClick = new EventEmitter();


  triggerClick(){
    this.onItemClick.emit();
  }
}
