<div *ngIf="progressStatus">
  <div class="progress-bar" [ngClass]="{'scrolled': isScrolled}">
    <div *ngIf="progressBarConfig.length" class="progress-line">
      <div class="line-item"
        *ngFor="let item of progressBarLinesConfig; let i = index"
        [ngClass]="{'active': i === progressStatus.activeIndex}"
      ></div>
    </div>
    <div class="item" *ngFor="let item of progressBarConfig; let i = index;" [ngClass]="{
      'active': i === progressStatus.activeIndex,
      'completed': i < (progressStatus.activeIndex || 0)
    }">
      <div class="round">
        <img class="icon" [src]="item.icon?.url" alt="">
        <img class="icon icon_mobile" [src]="item.icon_mobile?.url" alt="">
      </div>
      <span class="title">{{item.title}}</span>
    </div>
  </div>
</div>

