<app-main-layout [clickBack]="onClickBack" [disableWatchingRoutes]="true">
  <div class="main-heading">
    <h2 [innerHtml]="pageContent[0]?.dce_page_introduction?.title"></h2>
  </div>
  <div class="energy-efficiency-game">
    <h3 class="h5-m">{{pageContent[1]?.dce_energy_efficiency?.heading?.title}}</h3>
    <div
      [innerHtml]="pageContent[1]?.dce_energy_efficiency?.heading?.text"
      class="cs-inner-html-16 mb-24"
    ></div>
    <div class="image">
      <img [src]="uValue?.image" alt="">
      <span class="u-value">{{uValue?.value}}</span>
    </div>
    <strong class="title">{{glassOptions?.title}}</strong>
    <div class="names-list">
      <div
        *ngFor="let item of glassOptions?.glass_options_list"
        class="name"
        (click)="onSelect(item, 'glassOptions glass_options_list')"
        [ngClass]="{ selected: item.selected }"
      >
        {{ item.name }}
      </div>
    </div>
    <strong class="title">{{gasOptions?.title}}</strong>
    <div class="names-list">
      <div
        *ngFor="let item of gasOptions?.gas_options_list"
        class="name"
        (click)="onSelect(item, 'gasOptions gas_options_list')"
        [ngClass]="{ selected: item.selected }"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
  <div class="suggested-products">
    <strong class="title">{{suggestedProducts?.title}}</strong>
    <div
      [innerHtml]="suggestedProducts?.text"
      class="cs-inner-html-18"
    ></div>
  </div>
</app-main-layout>