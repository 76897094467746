import { Injectable } from '@angular/core';
import { ContentstackService } from '../../modules/contentstack/contentstack.service';
import * as Utils from "@contentstack/utils";
import { LoadingSpinnerService } from '../shared/components/loading-spinner/loading-spinner.service';

@Injectable({ providedIn: 'root' })
export class ContentstackQueryService {

  constructor(
    private cs: ContentstackService,
    private loadingSpinner: LoadingSpinnerService) {}

  renderOption = {
    ["span"]: (node, next) => {
      return next(node.children);
    },
  }

  getEntry(contentTypeUid: string, references = [], jsonRtePath = [], showSpinner = true): Promise<any> {
    if(showSpinner) this.loadingSpinner.show();
    return this.cs.stack().contentstack.ContentType(contentTypeUid)
      .Query()
      .includeReference(references)
      .toJSON()
      .find()
      .then(entry => {
        jsonRtePath.length > 0 &&
          Utils.jsonToHTML({
            entry,
            paths: jsonRtePath,
            renderOption: this.renderOption,
          });
          if(showSpinner) this.loadingSpinner.hide();
        return entry;
      }, error => {
        if(showSpinner) this.loadingSpinner.hide();
        console.log(error, error);
      });
  }

  getEntryWithQuery(contentTypeUid: string, { key, value }, references = [], jsonRtePath = [], showSpinner = true): Promise<any> {
    if(showSpinner) this.loadingSpinner.show();
    return this.cs.stack().contentstack.ContentType(contentTypeUid)
      .Query()
      .where(key, value)
      .includeReference(references)
      .toJSON()
      .find()
      .then(entry => {
        jsonRtePath.length > 0 &&
          Utils.jsonToHTML({
            entry,
            paths: jsonRtePath,
            renderOption: this.renderOption,
          });
          if(showSpinner) this.loadingSpinner.hide();
        return entry;
      }, error => {
        if(showSpinner) this.loadingSpinner.hide();
        console.log(error, error);
      });
  }
}
