<app-main-layout
  [clickNext]="onClickNext"
  [clickBack]="onClickBack"
  [activateNextButton$]="nextButtonState.activateNext$"
>
  <div class="main-heading">
    <h2 [innerHtml]="pageContent[0]?.dce_page_introduction?.title"></h2>
    <div
      [innerHtml]="pageContent[0]?.dce_page_introduction?.text"
      class="cs-inner-html-16"
    ></div>
  </div>
  <form class="contact-form" [formGroup]="homeownerForm">
    <app-radio [radioGroup]="radioGroup" [rounded]="true"></app-radio>
    <div
      *ngIf="
        homeownerForm.controls['onlyHomeowner'].value ===
        this.pageContent[1]?.dce_homeowner_form_data?.radio_buttons[1]
          .unique_name
      "
    >
      <h2
        class="mb-24"
        [innerHtml]="pageContent[1]?.dce_homeowner_form_data?.title"
      ></h2>
      <div
        *ngFor="let homeowner of homeownersList; index as i"
        class="homeowner-block"
        [formGroup]="homeowner"
      >
        <div class="input">
          <div class="homeowner-block_label-block">
            <span class="homeowner-block_label"
              >Additional Homeowner {{ i + 1 }}</span
            >
            <button class="remove-btn" (click)="onRemoveHomeOwner(i)">
              <img
                class="icon"
                src="/assets/icons/trash-2.svg"
                alt="remove-btn"
                aria-hidden="true"
              />
            </button>
          </div>

          <app-form-group
            label="Full Name"
            id="fullName"
            [required]="true"
            formControlName="fullName"
          ></app-form-group>
        </div>
        <div class="input">
          <app-form-group
            label="Phone Number"
            id="phone"
            formControlName="phone"
            type="phone"
          ></app-form-group>
        </div>
      </div>
      <button class="add-btn" (click)="onAddHomeowner()">
        <img
          class="icon"
          src="/assets/icons/add-homeowner.svg"
          alt="add-btn"
          aria-hidden="true"
        />
        Add additional homeowner
      </button>
    </div>
  </form>
</app-main-layout>
