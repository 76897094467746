<nav
  aria-label="Utility Navigation"
  class="utility-nav-desktop bg-brand-black utility-nav"
>
  <div>
    <div class="container">
      <ul *ngIf="headerMap">
        <li *ngFor="let item of headerMap.utility_nav">
          <a
            tabindex="0"
            class="utility-item"
            [href]="modifyUrl(item.link_item.href)"
            target="_blank"
            ><span class="control-group"
              ><span style="order: 1">{{ item.link_item.title }}</span
              ><span
                *ngIf="item.icon"
                class="shared-icon-container"
                [innerHtml]="icons[item.icon]"
              ></span></span
          ></a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<header
  class="header"
  [ngClass]="{ 'header-open': isMobileNavShown }"
  [style]="isScrolled ? 'top: 32px' : 'top: 80px'"
>
  <div *ngIf="headerMap">
    <div class="container">
      <nav aria-label="Main Navigation" class="bg-brand-blue main-nav">
        <span
          *ngIf="headerMap.window_nation_logo.url"
          class="header-logo-link"
          ><span class="control-group"
            ><span
              ><figure class="image">
                <img
                  alt="window-nation-knockout-logo.svg"
                  loading="lazy"
                  width="135"
                  height="48"
                  decoding="async"
                  data-nimg="1"
                  style="
                    color: transparent;
                    object-fit: cover;
                    object-position: center;
                  "
                  [src]="headerMap.window_nation_logo.url"
                /></figure></span></span
        ></span>
        <a tabindex="0" class="request-call" target="_blank"  *ngIf="headerMap?.cta_button_reference?.[0]?.cta_button" [href]="headerMap?.cta_button_reference?.[0]?.cta_button?.href">
          <span class="control-group">
            <span>{{headerMap?.cta_button_reference?.[0]?.cta_button?.title}}</span>
          </span>
        </a>
        <div class="header-nav-toggle-container">
          <button
            type="button"
            class="header-nav-toggle"
            (click)="toggleMobileNavigation()"
          >
            <span class="visually-hidden">Close Nav</span
            ><svg
              *ngIf="!isMobileNavShown"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              role="img"
              class="IconButton_icon__A_ory"
              aria-hidden="true"
              style="
                color: var(--icon-color, currentColor);
                width: var(--icon-width, var(--icon-size, 24px));
                height: var(--icon-height, var(--icon-size, 24px));
              "
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                d="M3 12h18M3 6h18M3 18h18"
              ></path></svg
            ><svg
              *ngIf="isMobileNavShown"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              role="img"
              class="IconButton_icon__A_ory"
              aria-hidden="true"
              style="
                color: var(--icon-color, currentColor);
                width: var(--icon-width, var(--icon-size, 24px));
                height: var(--icon-height, var(--icon-size, 24px));
              "
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                d="M18 6 6 18M6 6l12 12"
              ></path>
            </svg>
          </button>
        </div>
        <div class="header-mobile-nav" *ngIf="isMobileNavShown">
          <div class="bg-brand-blue request-call-frame" *ngIf="headerMap?.cta_button_reference?.[0]?.cta_button">
            <a
              tabindex="0"
              class="request-call"
              target="_blank"
              [href]="headerMap?.cta_button_reference?.[0]?.cta_button?.href"
              ><span class="shared_control-group__3ZCLX"
                ><span>{{headerMap?.cta_button_reference?.[0]?.cta_button?.title}}</span></span
              ></a
            >
          </div>
          <nav
            aria-label="Utility Navigation"
            class="bg-brand-black utility-nav"
          >
            <div>
              <div class="container">
                <ul>
                  <li *ngFor="let item of headerMap.utility_nav">
                    <a
                      tabindex="0"
                      target="_blank"
                      class="utility-item"
                      [href]="modifyUrl(item.link_item.href)"
                      ><span class="control-group"
                        ><span style="order: 1">{{ item.link_item.title }}</span
                        ><span
                          class="shared-icon-container"
                          [innerHtml]="icons[item.icon]"
                        ></span></span
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </nav>
    </div>
  </div>
</header>
