import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  Output,
  OnInit,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormGroupComponent } from '../form-group/form-group.component';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormGroupComponent],
})
export class CounterComponent implements OnInit, OnChanges {
  @Input() initialValue = 0;
  @Input() step = 0;
  @Input() min = 0;
  @Input() max?: number;
  @Input() type: string;
  @Input() label: string;
  formGroup: FormGroup;
  value = 0;
  @Output() valueChange = new EventEmitter();
  @Output() toggleMore = new EventEmitter();
  @Output() toggleLess = new EventEmitter();
  errorMessages = {
    windows: {
      max: 'Windows Maximum',
      min: 'Windows Minimum',
    },
    doors: {
      max: 'Doors Maximum',
      min: 'Doors Minimum',
    },
  };

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.errorMessages[this.type].max = `${this.max ? this.max : 0} ${
      this.errorMessages[this.type].max
    }`;
    this.errorMessages[this.type].min = `${this.min ? this.min : 0} ${
      this.errorMessages[this.type].min
    }`;

    const validators = [Validators.required, Validators.min(this.min)];
    if (this.max) {
      validators.push(Validators.max(this.max));
    }
    this.formGroup = this.fb.group({
      count: [this.initialValue, validators],
    });
    this.formGroup.valueChanges.subscribe(() => {
      this.updateValues();
    });
  }

  ngOnChanges(e: SimpleChanges) {
    if (e['initialValue']) {
      if(this.formGroup){
        this.formGroup.patchValue({
          count: this.initialValue,
        });
      }
    }
  }

  onClickMore(): void {
    if (this.formGroup.controls['count'].value < this.max) {
      this.formGroup.patchValue({
        count: (this.formGroup.controls['count'].value || 0) + this.step,
      });
      this.updateValues();
    }
  }

  updateValues() {
    this.valueChange.emit({
      value: this.formGroup.controls['count'].value,
      valid: this.formGroup.valid,
    });
    this.toggleMore.emit();
  }

  onClickLess(): void {
    if (this.formGroup.controls['count'].value > this.min) {
      this.formGroup.patchValue({
        count: this.formGroup.controls['count'].value - this.step,
      });
      this.updateValues();
    }
  }
}
