<button
  class="selection-item"
  [ngClass]="{
    selected: item.selected,
    'single': type === 'single'
  }"
  (click)="triggerClick()"
>
  <span class="checkmark"></span>
  <div class="icon-container">
    <img [src]="item.icon.url" alt="Card Icon" />
  </div>
  <div class="content">
    <p>
      {{ item.title }}
    </p>
  </div>
</button>
