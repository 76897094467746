<div class="custom-checkbox" [formGroup]="formGroup" [ngClass]="{
  error:
    !formGroup.controls[formControlName].valid
}">
  <label class="label-container">
    <input type="checkbox" [formControlName]="formControlName">
    <span class="custom-checkbox-btn"></span>
    <span class="label" *ngIf="!labelHTML">{{label}}</span>
    <div class="cs-inner-html-checkbox" *ngIf="labelHTML" [innerHTML]="labelHTML"></div>
  </label>
  <span class="error-message">*This box must be checked to continue</span>
</div>
