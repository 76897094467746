<app-main-layout [clickBack]="onClickBack" [disableWatchingRoutes]="true">
  <div class="main-heading">
    <h2 [innerHtml]="pageContent[0]?.dce_page_introduction?.title"></h2>
  </div>
  <ul class="financing-plans">
    <li *ngFor="let item of plans">
      <strong class="title">{{item.title}}</strong>
      <div class="cs-inner-html-18" [innerHtml]="item.text"></div>
      <dl class="info">
        <ng-container *ngFor="let infoItem of item.description_list">
          <dt>{{infoItem.name}}</dt>
          <dd>
            <span *ngIf="infoItem.unique_name === 'loan_period'" class="period-item-holder">
              <ng-container *ngFor="let _ of [].constructor(+infoItem.value)">
                <i class="period-item"></i>
              </ng-container>
            </span>

            <span *ngIf="infoItem.unique_name !== 'loan_period'">{{infoItem.value}}</span>
          </dd>
        </ng-container>
      </dl>
    </li>
  </ul>
</app-main-layout>