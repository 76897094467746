<div class="page-wrapper">
  <div class="left-column">
    <h2>404 Error</h2>
    <h3>Page Not Found</h3>
    <p>Oops... Looks like this window cannot be opened!</p>
    <app-button [handleClick]="goToTheHomePage" text="Go Back to Home Page" type="filled"></app-button>
  </div>
  <div class="right-column">
    <img src="/assets/icons/404.svg" alt="not-found-image">
  </div>
</div>
