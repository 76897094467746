import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ProgressBarConfigItem, ProgressBarState } from './progress-bar.model';

@Injectable({
  providedIn: 'root',
})
export class ProgressBarService {
  private subject = new Subject<ProgressBarState>();
  private progressBarConfigSubject$ = new BehaviorSubject([]);

  changeProgressBarStatus(value: ProgressBarState) {
    this.subject.next(value);
  }

  getChangedProgressBarStatus(): Observable<ProgressBarState> {
    return this.subject.asObservable();
  }

  changeProgressBarConfig(value: ProgressBarConfigItem[]) {
    this.progressBarConfigSubject$.next(value);
  }

  getProgressBarConfig(): Observable<ProgressBarConfigItem[]> {
    return this.progressBarConfigSubject$.asObservable();
  }
}
