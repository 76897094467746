import { CommonModule, Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { Router } from '@angular/router';

import {
  NAVIGATION_MAP,
  PAGES,
  PAGES_TO_VALIDATE_RANDOM_NAVIGATION,
  WITHOUT_ID_WHITELIST,
} from '../../constants/navigation-map';
import { ButtonComponent } from '../button/button.component';
import { ProgressBarComponent } from '../progress-bar/progress-bar.component';
import { ProgressBarService } from '../progress-bar/progress-bar.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-main-layout',
  standalone: true,
  imports: [CommonModule, RouterOutlet, ButtonComponent, ProgressBarComponent],
  providers: [],
  templateUrl: './main-layout.component.html',
  styleUrl: './main-layout.component.scss',
})
export class MainLayoutComponent implements OnInit, OnDestroy {
  private queryParamsSubscription: Subscription;
  private subscription: Subscription;
  private dataSub: Subscription;
  private nextButtonSub: Subscription;
  private subscriptions: Subscription = new Subscription();
  private url: string;
  isAbleGoNext = true;
  nextButton = null;
  backButton = null;
  exitButton = null;
  @Input() clickNext?: () => Observable<any>;
  @Input() clickBack?: () => Observable<any>;
  @Input() backRoute?: () => string;
  @Input() activateNextButton$: Observable<any>;
  activateNextButton: boolean = false;
  @Output() manuallyClickNext? = new EventEmitter();
  @Input() disableWatchingRoutes: boolean;
  showProgressBar: boolean = false;
  pageResumed: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private progressBarService: ProgressBarService,
    private location: Location,
    private navigationService: NavigationService,
    private router: Router,
    private dataService: DataService
  ) {
    this.subscription = this.route.url.subscribe((segments) => {
      this.url = segments.map((s) => s.path).join('/');
      window.scrollTo(0, 0);
      this.queryParamsSubscription = this.route.queryParams.subscribe(
        (params) => {
          if (params['resume']) {
            this.pageResumed = true;
          }
          if (!WITHOUT_ID_WHITELIST.includes(this.url) && !params['id']) {
            this.navigationService.navigateToPath('');
          }
        }
      );
      this.preventFromRandomRoutes(segments[0].path);
    });
  }

  preventFromRandomRoutes(path) {
    if (
      !this.pageResumed &&
      PAGES_TO_VALIDATE_RANDOM_NAVIGATION.includes(path)
    ) {
      const currentPage = localStorage.getItem(
        `currentPage_${this.dataService.id}`
      );
      if (
        !currentPage ||
        this.dataService.stepsData.appointmentRequest.customer.firstName
      ) {
        if (this.dataService.id) {
          localStorage.setItem(`currentPage_${this.dataService.id}`, path);
        }
      } else {
        this.router.navigate([currentPage], {
          replaceUrl: true,
          queryParamsHandling: 'merge',
        });
      }
    }
    this.pageResumed = false;
  }

  ngOnInit(): void {
    this.changeProgressBarStatus();
    this.layoutComponentsSetup();
    if (this.activateNextButton$) {
      this.nextButtonSub = this.activateNextButton$.subscribe((res) => {
        this.activateNextButton = res;
      });
    }
    this.handleBrowserBackNavigation(this.disableWatchingRoutes);
  }

  layoutComponentsSetup() {
    const url = NAVIGATION_MAP[this.url];
    if (url.nextButton) this.nextButton = url.nextButton;
    if (url.backButton) this.backButton = url.backButton;
    if (url.exitButton) this.exitButton = url.exitButton;
  }
  changeProgressBarStatus(): void {
    this.progressBarService.changeProgressBarStatus(
      NAVIGATION_MAP[this.url].status
    );
    this.showProgressBar = !!NAVIGATION_MAP[this.url].status;
  }

  onClickNext = (): void => {
    this.activateNextButton = false;
    if (this.clickNext) {
      this.clickNext().subscribe((value: any) => {
        if (
          (typeof value === 'boolean' && value) ||
          typeof value === 'string'
        ) {
          if (Object.values(PAGES).includes(value as string)) {
            this.navigationService.navigateToPath(value as string);
          } else {
            this.navigationService.navigateToPath(
              NAVIGATION_MAP[this.url].nextButton.route
            );
          }
        }
      });
    } else if (this.manuallyClickNext) {
      this.manuallyClickNext.emit();
    } else {
      this.navigationService.navigateToPath(
        NAVIGATION_MAP[this.url].nextButton.route
      );
    }
  };

  onClickBack = (): void => {
    if (this.clickBack) {
      this.clickBack().subscribe((value: any) => {
        if (
          (typeof value === 'boolean' && value) ||
          typeof value === 'string'
        ) {
          if (NAVIGATION_MAP[this.url].backButton.useHistory) {
            this.location.back();
          } else if (this.backRoute) {
            this.navigationService.navigateToPath(this.backRoute());
          } else {
            if (Object.values(PAGES).includes(value as string)) {
              this.navigationService.navigateToPath(value as string);
            } else {
              this.navigationService.navigateToPath(
                NAVIGATION_MAP[this.url].backButton.route
              );
            }
          }
        }
      });
    } else {
      this.navigationService.navigateToPath(
        NAVIGATION_MAP[this.url].backButton.route
      );
    }
  };

  handleBrowserBackNavigation = (useHistory) => {
    if (!useHistory) {
      history.pushState(null, '');
      const sub = fromEvent(window, 'popstate').subscribe(() => {
        history.pushState(null, '');
        setTimeout(() => {
          this.onClickBack();
        });
      });
      this.subscriptions.add(sub);
    }
  };

  onClickExit = (): void => {
    this.dataService.resetStepsData();
    this.router.navigate(['/']);
  };

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
    if (this.dataSub) this.subscription.unsubscribe();
    if (this.nextButtonSub) this.nextButtonSub.unsubscribe();
    if (this.queryParamsSubscription)
      this.queryParamsSubscription.unsubscribe();
    if (this.subscriptions) this.subscriptions.unsubscribe();
  }
}
