<app-main-layout
  [clickNext]="onClickNext"
  [clickBack]="onClickBack"
  [activateNextButton$]="nextButtonState.activateNext$"
>
  <div class="main-heading">
    <h2 [innerHtml]="pageContent[0]?.dce_page_introduction?.title"></h2>
    <div
      [innerHtml]="pageContent[0]?.dce_page_introduction?.text"
      class="cs-inner-html-16"
    ></div>
  </div>
  <div class="images-block">
    <img
      *ngIf="currentImage"
      width="278"
      height="160"
      [src]="currentImage"
      alt="Image"
    />
  </div>
  <app-counter
    class="counter-input"
    [step]="counterStep"
    [min]="counterMinValue"
    [max]="counterMaxValue"
    [initialValue]="counterInitialValue"
    type="windows"
    label="Number of Windows"
    (valueChange)="onCounterValueChange($event)"
  >
  </app-counter>
</app-main-layout>
