import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-ppl-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ppl-card.component.html',
  styleUrl: './ppl-card.component.scss',
})
export class PplCardComponent {
  @Input() isPPL = false;
  @Input() text: string;
}
