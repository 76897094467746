<footer class="bg-brand-black footer" *ngIf="footerMap" [ngClass]="{'has-offer': hasOfferBar}">
  <div class="section">
    <div class="container">
      <div class="footer-top">
        <a
          href="https://windownation.com"
          class="footer-logo-link"
          target="_blank"
          ><span
            ><span
              ><figure>
                <img
                  [src]="footerMap.window_nation_logo.url"
                  style="object-fit: cover; object-position: center center"
                /></figure></span></span
        ></a>
        <ul>
          <li *ngFor="let icon of footerMap.social_icons">
            <a
              [href]="icon.social_media_link.href"
              [title]="icon.social_media_link.title"
              target="_blank"
              class="footer-social-link"
              ><span class="control-group"
                ><span [innerHtml]="icons[icon.icon]"></span></span
            ></a>
          </li>
        </ul>
      </div>
      <div
        class="grid footer-content"
        *ngIf="footerMap.link_lists && footerMap.link_lists.length > 0"
        style="grid-template-columns: repeat(12, 1fr)"
      >
        <div
          class="column"
          style="--col-md-span: 6; --col-lg-span: 3"
          *ngFor="let column of footerMap.link_lists"
        >
          <h2 class="title">{{column.header}}</h2>
          <ul>
            <li *ngFor="let item of column.item">
              <a
                tabindex="0"
                [href]="modifyUrl(item.href)"
                target="_blank"
                class=""
                ><span class=""
                  ><span>{{item.title}}</span></span
                ></a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="footer-bottom">
        <p>{{footerMap?.subfooter?.subfooter_text}}</p>
        <div class="footer-policies">
          <p>{{footerMap?.subfooter?.copyright_language}}</p>
          <ul>
            <li *ngFor="let item of footerMap?.subfooter?.policies?.policy_link">
              <a
                tabindex="0"
                target="_blank"
                [href]="modifyUrl(item.href)"
                ><span
                  ><span>{{item.title}}</span></span
                ></a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>
