import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: "app-intro-card",
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  templateUrl: "./intro-card.component.html",
  styleUrl: "./intro-card.component.scss",
})
export class IntroCardComponent {
  @Input() cardData: any;
  @Input() selected: boolean;
  @Input() text?: string;
  @Input() isUppercase?: boolean = false;
  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
  emitClick = () => {
    this.onClick.emit(true);
  }
}
