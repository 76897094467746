import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonComponent } from 'src/app/shared/components/button/button.component';
import { MainLayoutComponent } from 'src/app/shared/components/main-layout/main-layout.component';
import { ContentstackModule } from 'src/modules/contentstack/contentstack.module';
import { ContentstackQueryService } from 'src/app/services/cs.query.service';

@Component({
  selector: 'app-call-center',
  standalone: true,
  imports: [
    CommonModule,
    ContentstackModule,
    ButtonComponent,
    MainLayoutComponent,
  ],
  providers: [ContentstackQueryService],
  templateUrl: './call-center.component.html',
  styleUrl: './call-center.component.scss',
})
export class CallCenterComponent implements OnInit {
  pageContent: any = [];
  constructor(private contentstack: ContentstackQueryService) {
    this.getEntry();
  }

  ngOnInit() {}

  getEntry() {
    this.contentstack
      .getEntryWithQuery(
        'dce_page',
        { key: 'url', value: '/dce-not-qualified' }, // TODO: change value in contentstack to dce-call-center
        [],
        []
      )
      .then(
        (entry) => {
          this.pageContent = entry[0][0]?.page_components;
          console.log('this.pageContent', this.pageContent);
        },
        (err) => {}
      );
  }

  onButtonClick = () => {
    window.location.href = `tel:${this.pageContent[1]?.dce_call_us?.button?.link}`;
  };
}
