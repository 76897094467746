import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContentstackModule } from 'src/modules/contentstack/contentstack.module';
import { ContentstackQueryService } from 'src/app/services/cs.query.service';
import { IntroCardComponent } from 'src/app/shared/components/intro-card/intro-card.component';
import { ButtonComponent } from 'src/app/shared/components/button/button.component';
import { Subscription, fromEvent } from 'rxjs';
import { PAGES } from 'src/app/shared/constants/navigation-map';
import { NavigationService } from 'src/app/services/navigation.service';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-intro',
  standalone: true,
  imports: [
    CommonModule,
    ContentstackModule,
    IntroCardComponent,
    ButtonComponent,
  ],
  providers: [ContentstackQueryService],
  templateUrl: './intro.component.html',
  styleUrl: './intro.component.scss',
})
export class IntroComponent implements OnInit, OnDestroy {
  public selected = '';
  pageContent: any;
  optionsList;
  sub: Subscription;
  popStateSub: Subscription;
  getDataSub: Subscription;
  constructor(
    private contentstack: ContentstackQueryService,
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private dataService: DataService
  ) {
    this.getEntry();
  }

  ngOnInit(): void {
    history.pushState(null, '');

    this.popStateSub = fromEvent(window, 'popstate').subscribe((_) => {
      history.pushState(null, '');
    });
    const resume = this.route.snapshot.queryParamMap.get('resume');
    if (this.dataService.id && resume) {
      this.getDataSub = this.dataService
        .getStepsData(this.dataService.id)
        .subscribe();
    }
  }

  getEntry() {
    this.contentstack
      .getEntryWithQuery('dce_page', { key: 'url', value: '/home' }, [], [])
      .then(
        (entry) => {
          this.pageContent = entry[0][0]?.page_components;
          this.optionsList =
            this.pageContent[1]?.dce_get_started_options?.options_list;
          this.navigationService.setHasOfferBar(
            !!this.pageContent[0]?.dce_intro?.offer
          );
        },
        (err) => {}
      );
  }

  onSelect = (val: string): void => {
    this.selected = val;
    if (this.selected) {
      localStorage.setItem('getStartedSelection', this.selected);
      this.navigationService.navigateToPath(this.selected);
    }
  };

  onClickRequestCall = (e) => {
    e.preventDefault();
    this.navigationService.navigateToPath(PAGES.REQUEST_CALL);
  };

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
    if (this.popStateSub) this.popStateSub.unsubscribe();
    if (this.getDataSub) this.getDataSub.unsubscribe();
    this.navigationService.setHasOfferBar(false);
  }
}
