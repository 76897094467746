export function formatPhoneNumber(phoneNumber) {
  const phoneStr = phoneNumber.toString();
  const formattedPhoneNumber = phoneStr.replace(/^(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3');

  return formattedPhoneNumber;
}

export function isAbsoluteURlWithHttp(url: string): boolean {
  return /^(?:[a-zA-Z]+:)?\/\//.test(url);
}

export function getAbsoluteUrl(link: string): string {
  const basePath = "https://www.windownation.com";
  if(isAbsoluteURlWithHttp(link) || link.includes('tel:')){
    return link;
  } else if(link.includes('www.')){
    return 'https://' + link
  } else {
    return basePath + link
  }
}