<div *ngIf="running" class="timer-widget" [ngClass]="{ danger: danger }">
  <img src="/assets/icons/clock.svg" alt="clock icon">
  <p class="timer">{{ time | async }}</p>
  <p>Time Remaining</p>
</div>
<div *ngIf="showAlert" class="alert-container">
  <div class="alert-box">
    <img src="/assets/icons/road-sign-warning.svg" alt="warning icon">
    <p class="alert-msg">Whoops! We’ve released your appointment time because your time to book has expired.</p>
    <app-button [handleClick]="goToTimeBooking" text="Select A New Time" type="filled"></app-button>
  </div>
</div>