import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private isAppEmbeddedSubject = new BehaviorSubject<boolean>(false);
  isAppEmbedded$ = this.isAppEmbeddedSubject.asObservable();
  private hasOfferBarSubject = new BehaviorSubject<boolean>(false);
  hasOfferBar$ = this.isAppEmbeddedSubject.asObservable();
  constructor(private router: Router) {}

  navigateToPath(path: string, queryParams?: { [key: string]: any }): void {
    const params = { queryParamsHandling: 'merge' } as NavigationExtras;
    if (queryParams) {
      params['queryParams'] = queryParams;
    }
    this.router.navigate([path], params);
  }

  setIsEmbedded(value: boolean) {
    this.isAppEmbeddedSubject.next(value);
  }

  getIsEmbedded(): Observable<any> {
    return this.isAppEmbeddedSubject.asObservable();
  }
  setHasOfferBar(value: boolean) {
    this.hasOfferBarSubject.next(value);
  }

  getHasOfferBar(): Observable<any> {
    return this.hasOfferBarSubject.asObservable();
  }
}
