import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContentstackModule } from 'src/modules/contentstack/contentstack.module';
import { ContentstackQueryService } from 'src/app/services/cs.query.service';
import { ButtonComponent } from 'src/app/shared/components/button/button.component';
import { MainLayoutComponent } from 'src/app/shared/components/main-layout/main-layout.component';
import { ProcessInfoCardComponent } from 'src/app/shared/components/process-info-card/process-info-card.component';
import { PAGES } from 'src/app/shared/constants/navigation-map';
import { NavigationService } from 'src/app/services/navigation.service';
import { DataService } from 'src/app/services/data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-whats-next',
  standalone: true,
  imports: [
    CommonModule,
    ContentstackModule,
    ButtonComponent,
    MainLayoutComponent,
    ProcessInfoCardComponent,
  ],
  providers: [ContentstackQueryService],
  templateUrl: './whats-next.component.html',
  styleUrl: './whats-next.component.scss',
})
export class WhatsNextComponent implements OnInit, OnDestroy {
  pageContent: any = [];
  whatsNext;
  sub: Subscription;
  title = {
    show: false,
    hasDate: false
  }

  constructor(
    private contentstack: ContentstackQueryService,
    private navigationService: NavigationService,
    private dataService: DataService
  ) {
    this.getEntry();
  }

  ngOnInit() {}

  getEntry() {
    this.contentstack
      .getEntryWithQuery(
        'dce_page',
        { key: 'url', value: '/whats-next' },
        [],
        []
      )
      .then(
        (entry) => {
          this.pageContent = entry[0][0]?.page_components;
          this.whatsNext = this.pageContent[1]?.process_info;
          if (this.dataService.id) {
            this.sub = this.dataService
              .getStepsData(this.dataService.id)
              .subscribe((data) => {
                this.title = {
                  show: true,
                  hasDate: !!data.appointmentRequest?.appointment?.appointmentDateTime
                }
              });
          }
        },
        (err) => {}
      );
  }

  onClickNext = () => {
    this.navigationService.navigateToPath(PAGES.EXPLORE_RESOURCES);
  }

  ngOnDestroy(): void {
      if(this.sub) this.sub.unsubscribe();
  }
}
