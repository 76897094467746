import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContentstackModule } from 'src/modules/contentstack/contentstack.module';
import { ContentstackQueryService } from 'src/app/services/cs.query.service';
import { MainLayoutComponent } from 'src/app/shared/components/main-layout/main-layout.component';
import { ReplaceUsernamePipe } from 'src/app/shared/pipes/replace-username.pipe';
import { BlogPostCardComponent } from 'src/app/shared/components/blog-post-card/blog-post-card.component';
import { DataService } from 'src/app/services/data.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-inside-sales',
  standalone: true,
  imports: [
    CommonModule,
    ContentstackModule,
    MainLayoutComponent,
    ReplaceUsernamePipe,
    BlogPostCardComponent,
  ],
  providers: [ContentstackQueryService],
  templateUrl: './inside-sales.component.html',
  styleUrl: './inside-sales.component.scss',
})
export class InsideSalesComponent implements OnInit {
  pageContent: any = [];
  name: string = '';
  steps: any;
  blogPosts: any;
  constructor(
    private contentstack: ContentstackQueryService,
    private dataService: DataService
  ) {
    this.getEntry();
  }

  ngOnInit() {}

  getEntry() {
    this.contentstack
      .getEntryWithQuery(
        'dce_page',
        { key: 'url', value: '/inside-sales' },
        [],
        []
      )
      .then(
        (entry) => {
          this.pageContent = entry[0][0]?.page_components;
          this.steps = this.pageContent[1]?.dce_steps_list;
          this.blogPosts = this.pageContent[2]?.dce_blog_posts;
          this.name = this.dataService.username;
        },
        (err) => {}
      );
  }

  onClickBack = (): Observable<any> => {
    return of(true);
  };
}
