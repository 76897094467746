<div class="page-wrapper" *ngIf="pageContent">
  <div class="content-wrapper">
    <div class="left-column">
      <h1>{{ pageContent[0].dce_intro.main_title }}</h1>
      <div class="cs-inner-html-18" [innerHTML]="pageContent[0].dce_intro.text"></div>
    </div>
    <div class="right-column">
      <div class="card">
        <div class="invitation-title">
          <span>{{pageContent[1]?.dce_get_started_options?.title}}</span>
        </div>
        <div class="cards-wrapper">
          <app-intro-card
            *ngFor="let option of optionsList"
            [cardData]="option"
            (onClick)="onSelect(option.link)"
            [text]="option.button_text"
            [isUppercase]="true"
          ></app-intro-card>
        </div>
      </div>
      <div class="text-link" *ngIf="pageContent[1]?.dce_get_started_options?.request_a_call?.button_text">
        <a (click)="onClickRequestCall($event)" href="#">{{pageContent[1]?.dce_get_started_options?.request_a_call?.button_text}}</a>
      </div>
    </div>
  </div>
  <div class="offer-bar" *ngIf="pageContent[0]?.dce_intro?.offer">
    <div class="frame">
      <div class="icon">
        <img src="assets/icons/offer-icon.svg" alt="">
      </div>
      <div class="offer-bar-description">
        <strong class="offer-title">{{pageContent[0]?.dce_intro?.offer}}</strong>
        <p>{{pageContent[0]?.dce_intro?.offer_description}}</p>
      </div>
    </div>
  </div>
</div>
