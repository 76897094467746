<app-main-layout [clickNext]="onClickNext" [clickBack]="onClickBack" [activateNextButton$]="nextButtonState.activateNext$">
  <app-timer></app-timer>
  <div class="main-heading">
    <h2 [innerHtml]="pageContent[0]?.dce_page_introduction?.title"></h2>
    <div
      [innerHtml]="pageContent[0]?.dce_page_introduction?.text"
      class="cs-inner-html-16"
    ></div>
  </div>
  <form class="contact-form" [formGroup]="additionalCommentsForm">
    <app-form-group
      label="Comments"
      id="comments"
      formControlName="comments"
      placeholder="Please add additional comments here..."
      [textarea]="true"
      [counter]="3000"
      class="mb-24 d-block"
    ></app-form-group>
    <h2 class="mb-24" [innerHtml]="topics?.title"></h2>
    <div class="topics-wrapper">
      <app-topic-button
        *ngFor="let item of topics?.topics_list; trackBy:identify"
        [text]="item.title"
        (onItemClick)="onSelectTopic(item)"
        [selected]="item.selected"
        class="child"
      ></app-topic-button>
    </div>
  </form>
</app-main-layout>
