<div class="radio-wrapper" [formGroup]="formGroup">
  <div *ngFor="let item of radioGroup">
    <div class="custom-radio">
      <label>
        <input
          type="radio"
          [formControlName]="item.formControlName"
          [value]="pickValue(item)"
        />
        <span class="custom-radio-btn" [ngClass]="{ rounded: rounded }"></span>
        {{ item.label }}
      </label>
    </div>
  </div>
</div>
