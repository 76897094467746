import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from '../button/button.component';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-blog-post-card',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  templateUrl: './blog-post-card.component.html',
  styleUrl: './blog-post-card.component.scss',
})
export class BlogPostCardComponent {
  @Input() card: any;
  constructor(private navigationService: NavigationService) {}

  onButtonClick = () => {
    this.navigationService.navigateToPath(this.card.read_more_button.link);
  };
}
