<app-main-layout
  [clickNext]="onClickNext"
  [clickBack]="onClickBack"
  [activateNextButton$]="nextButtonState.activateNext$"
>
  <div class="main-heading">
    <h2 [innerHtml]="pageContent[0]?.dce_page_introduction?.title"></h2>
    <div
      [innerHtml]="pageContent[0]?.dce_page_introduction?.text"
      class="cs-inner-html-16"
    ></div>
  </div>
  <form class="contact-form" [formGroup]="addressForm">
    <app-form-group
      label="Street Address 1"
      [required]="true"
      [id]="'streetAddress1'"
      formControlName="streetAddress1"
    ></app-form-group>
    <app-form-group
      label="Street Address 2"
      id="streetAddress2"
      formControlName="streetAddress2"
    ></app-form-group>
    <div class="inputs-row">
      <app-form-group
        label="City"
        [required]="true"
        id="city"
        formControlName="city"
      ></app-form-group>
      <div
        class="select-wrapper"
        [ngClass]="{
          error:
            addressForm.controls['state'].touched &&
            !addressForm.controls['state'].valid
        }"
      >
        <label class="state-label" for="state">State <span>*</span></label>
        <div class="icon-wrapper">
          <img
            *ngIf="
              addressForm.controls['state'].value.trim() === '' &&
              !addressForm.controls['state'].touched
            "
            src="/assets/icons/cross-circle.svg"
            alt="No Value"
            class="empty"
          />
          <img
            *ngIf="
              addressForm.controls['state'].touched &&
              !addressForm.controls['state'].valid
            "
            src="/assets/icons/alert-circle.svg"
            alt="Invalid Value"
            class="invalid"
          />
          <img
            *ngIf="
              addressForm.controls['state'].value.trim() !== '' &&
              addressForm.controls['state'].valid
            "
            src="/assets/icons/check-circle.svg"
            alt="Valid Value"
            class="valid"
          />
        </div>
        <ng-select
          #stateSelect
          appearance="outline"
          labelForId="state"
          formControlName="state"
          [clearable]="false"
          class="state-select"
          bindLabel="name"
          bindValue="id"
          [dropdownPosition]="'top'"
        >
          <ng-option *ngFor="let option of statesList" [value]="option.short">{{
            option.short
          }}</ng-option>
        </ng-select>
        <div class="error-msg">
          <div
            *ngIf="
              addressForm.controls['state'].invalid &&
              addressForm.controls['state'].touched
            "
          >
            <span *ngIf="addressForm.controls['state'].errors?.['required']">{{
              ERROR_MESSAGES.FIELD_REQUIRED
            }}</span>
          </div>
        </div>
      </div>
      <app-form-group
        label="Zipcode"
        [required]="true"
        id="zipcode"
        formControlName="zipcode"
        type="zip"
      ></app-form-group>
    </div>
    <div
      class="ownership-check"
      *ngIf="pageContent[1]?.address_form_data?.ownership_check"
    >
      <h2
        [innerHtml]="pageContent[1]?.address_form_data?.ownership_check?.title"
      ></h2>
      <div
        [innerHtml]="pageContent[1]?.address_form_data?.ownership_check?.text"
        class="cs-inner-html-16 mb-24"
      ></div>
      <app-radio [radioGroup]="radioGroup" [rounded]="true"></app-radio>
      <div class="text-link" *ngIf="!showOtherBlock">
        <a (click)="switchOther(true, $event)" href="#">Other</a>
      </div>
      <div *ngIf="showOtherBlock">
        <div class="input">
          <button
            class="remove-btn"
            (click)="switchOther(false)"
          >
            <img
              class="icon"
              src="/assets/icons/trash-2.svg"
              alt="remove-btn"
              aria-hidden="true"
            />
          </button>
          <app-form-group
            [label]="pageContent[2]?.dce_text_link?.link_text"
            id="propertyTypeOtherText"
            [required]="true"
            [counter]="otherTextfieldMaxLength"
            formControlName="propertyTypeOtherText"
            placeholder="Enter your type of property here"
          >
          </app-form-group>
        </div>
      </div>
    </div>
  </form>
</app-main-layout>
