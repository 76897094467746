import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ContentstackQueryService } from 'src/app/services/cs.query.service';
import { ContentstackModule } from 'src/modules/contentstack/contentstack.module';
import { NavigationService } from 'src/app/services/navigation.service';
import { Subscription } from 'rxjs';
import { getAbsoluteUrl } from '../../utils/utils';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, ContentstackModule],
  providers: [ContentstackQueryService],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent implements OnInit, OnDestroy {
  footerMap;
  icons: { [key: string]: SafeHtml };
  hasOfferBar: boolean = false;
  offerSub: Subscription;
  constructor(
    private contentstack: ContentstackQueryService,
    private sanitizer: DomSanitizer,
    private navigationService: NavigationService
  ) {
    this.icons = {
      Facebook: this.sanitizer.bypassSecurityTrustHtml(
        `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" role="img" style="color:var(--icon-color, currentcolor);width:var(--icon-width, var(--icon-size, 32px));height:var(--icon-height, var(--icon-size, 32px))"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></svg>`
      ),
      X: this.sanitizer.bypassSecurityTrustHtml(
        `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" role="img" style="color:var(--icon-color, currentcolor);width:var(--icon-width, var(--icon-size, 32px));height:var(--icon-height, var(--icon-size, 32px))"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M18 6 6 18M6 6l12 12"></path></svg>`
      ),
      Linkedin: this.sanitizer.bypassSecurityTrustHtml(
        `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" role="img" style="color:var(--icon-color, currentcolor);width:var(--icon-width, var(--icon-size, 32px));height:var(--icon-height, var(--icon-size, 32px))"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-4 0v7h-4v-7a6 6 0 0 1 6-6M6 9H2v12h4zM4 6a2 2 0 1 0 0-4 2 2 0 0 0 0 4"></path></svg>`
      ),
      Instagram: this.sanitizer.bypassSecurityTrustHtml(
        `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" role="img" style="color:var(--icon-color, currentcolor);width:var(--icon-width, var(--icon-size, 32px));height:var(--icon-height, var(--icon-size, 32px))"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17.5 6.5h.01M7 2h10a5 5 0 0 1 5 5v10a5 5 0 0 1-5 5H7a5 5 0 0 1-5-5V7a5 5 0 0 1 5-5m9 9.37a4 4 0 1 1-7.914 1.174A4 4 0 0 1 16 11.37"></path></svg>`
      ),
      Youtube: this.sanitizer.bypassSecurityTrustHtml(
        `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" role="img" style="color:var(--icon-color, currentcolor);width:var(--icon-width, var(--icon-size, 32px));height:var(--icon-height, var(--icon-size, 32px))"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2c.312-1.732.466-3.49.46-5.25a29 29 0 0 0-.46-5.33"></path><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m9.75 15.02 5.75-3.27-5.75-3.27z"></path></svg>`
      ),
      Pinterest: '',
    };
  }
  ngOnInit() {
    this.contentstack.getEntry('footer', [], []).then(
      (entry) => {
        this.footerMap = entry[0][0];
      },
      (err) => {}
    );
    this.offerSub = this.navigationService.getHasOfferBar().subscribe((res) => {
      this.hasOfferBar = res;
    });
  }
  modifyUrl(link){
    return getAbsoluteUrl(link);
  }
  ngOnDestroy(): void {
    if (this.offerSub) this.offerSub.unsubscribe();
  }
}
