import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  ReactiveFormsModule,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

import { ProcessInfoCardComponent } from 'src/app/shared/components/process-info-card/process-info-card.component';
import { FormGroupComponent } from 'src/app/shared/components/form-group/form-group.component';
import { ButtonComponent } from 'src/app/shared/components/button/button.component';
import { ContentstackModule } from '../../../modules/contentstack/contentstack.module';
import { ContentstackQueryService } from '../../services/cs.query.service';
import { VALIDATION_PATTERNS } from 'src/app/shared/constants/text-field';
import {
  FORBIDDEN_PHONES,
  forbiddenPhoneValidator,
} from 'src/app/shared/constants/phone-validator';
import { NavigationService } from 'src/app/services/navigation.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-request-call',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ContentstackModule,
    ProcessInfoCardComponent,
    FormGroupComponent,
    ButtonComponent,
  ],
  providers: [ContentstackQueryService],
  templateUrl: './request-call.component.html',
  styleUrl: './request-call.component.scss',
})
export class RequestCallComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  pageData: any;
  submitSub: Subscription;
  userForm: FormGroup;
  formChangesSub: Subscription;
  isReadyToSubmit: boolean = false;
  isRequestSubmitted = false;

  constructor(
    private contentstack: ContentstackQueryService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private navigationService: NavigationService,
    private dataService: DataService
  ) {
    this.subscription = this.route.url.subscribe((segments) => {
      const url = `/${segments.map((s) => s.path).join('/')}`;
      this.getEntry(url);
    });
    this.userForm = this.fb.group({
      firstName: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.pattern(VALIDATION_PATTERNS.text),
        ],
      ],
      lastName: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.pattern(VALIDATION_PATTERNS.text),
        ],
      ],
      phoneNumber: [
        '',
        [Validators.required, forbiddenPhoneValidator(FORBIDDEN_PHONES)],
      ],
      email: [
        '',
        [Validators.required, Validators.pattern(VALIDATION_PATTERNS.email)],
      ],
      zipcode: ['', Validators.required],
    });
    this.subscribeToFormChanges();
  }

  ngOnInit(): void {}

  getEntry(url: string): void {
    this.contentstack
      .getEntryWithQuery(
        'dce_page',
        { key: 'url', value: '/request-call' },
        [],
        []
      )
      .then(
        (entry) => {
          this.pageData = entry[0][0]?.page_components;
        },
        (err) => {}
      );
  }

  subscribeToFormChanges(): void {
    this.formChangesSub = this.userForm.valueChanges.subscribe(
      (value) => {
        this.checkFormValidity();
      }
    );
  }

  checkFormValidity() {
    setTimeout((_) => {
      this.isReadyToSubmit = !!this.userForm.valid;
    });
  }

  onSubmit = () => {
    if (this.userForm.valid) {
      const formData = { ...this.userForm.value };
      const stepsData = { ...this.dataService.stepsData };
      stepsData.status = 'CallRequested';
      stepsData.sessionData = JSON.stringify({});
      stepsData.appointmentRequest.customer.firstName = formData.firstName;
      stepsData.appointmentRequest.customer.lastName = formData.lastName;
      stepsData.appointmentRequest.customer.phonePrimary = formData.phoneNumber;
      stepsData.appointmentRequest.customer.email = formData.email;
      stepsData.appointmentRequest.property.zipcode = formData.zipcode;
      this.submitSub = this.dataService
        .saveStepsData(stepsData)
        .subscribe((res) => {
          console.log('Request Call submitted');
          this.isRequestSubmitted = true;
          this.dataService.resetStepsData();
        });
    }
    this.userForm.markAllAsTouched();
  };

  onClickBack = () => {
    this.navigationService.navigateToPath('');
  };

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
    if (this.formChangesSub) this.formChangesSub.unsubscribe();
  }
}
