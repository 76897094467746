import { Component, Input, Output, EventEmitter } from "@angular/core";
import { CommonModule } from "@angular/common";

@Component({
  selector: 'app-topic-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './topic-button.component.html',
  styleUrl: './topic-button.component.scss'
})
export class TopicButtonComponent {
  @Input() text: string = "";
  @Input() selected: boolean;
  @Output() onItemClick = new EventEmitter();

  handleClick() {
    this.onItemClick.emit();
  }
}
