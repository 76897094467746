<app-main-layout
  [clickNext]="onClickNext"
  [clickBack]="onClickBack"
  [activateNextButton$]="nextButtonState.activateNext$"
>
  <app-timer></app-timer>
  <div class="main-heading">
    <h2 [innerHtml]="pageContent[0]?.dce_page_introduction?.title"></h2>
  </div>
  <form class="contact-form" [formGroup]="confirmationForm">
    <section>
      <h3 class="h5-m">
        {{ confirmationContent?.sections[0]?.your_appointment?.title }}
      </h3>
      <div
        class="appointment-block"
        *ngIf="appointmentInfo && appointmentInfo.day"
      >
        <div>
          <p>
            {{ appointmentInfo.day }}. {{ appointmentInfo.formattedDate }}
            {{ appointmentInfo.year }}
          </p>
          <p>
            {{ appointmentInfo.startTime }} - {{ appointmentInfo.endTime }} EST
          </p>
        </div>
        <div>
          <button
            *ngIf="appointmentError"
            class="edit-btn"
            (click)="goToTimeBooking()"
          >
            Edit
          </button>
        </div>
      </div>
      <div
        class="appointment-block"
        *ngIf="appointmentInfo && appointmentInfo?.noneWorksForMe"
      >
        <p>We will call to setup your appointment!</p>
      </div>
      <div class="no-appointment" *ngIf="!appointmentInfo && appointmentError">
        <p>*Appointment slot no longer valid</p>
      </div>
    </section>
    <section>
      <h3 class="h5-m">
        {{ confirmationContent?.sections[1]?.contact_info?.title }}
      </h3>
      <div *ngIf="contactInfo">
        <p>{{ contactInfo.primaryEmail }}</p>
        <p>{{ contactInfo.primaryPhone }}</p>
      </div>
    </section>
    <section>
      <h3 class="h5-m">
        {{ confirmationContent?.sections[2]?.project_details?.title }}
      </h3>
      <p *ngIf="windowCount">Window Count: {{ windowCount }}</p>
      <p *ngIf="doorCount">Door Count: {{ doorCount }}</p>
    </section>
    <app-checkbox
      class="attendance-checkbox"
      formControlName="attendanceConfirmation"
      [label]="confirmationContent?.checkbox_text"
    ></app-checkbox>
  </form>
</app-main-layout>
