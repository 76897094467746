import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceUsername',
  standalone: true
})
export class ReplaceUsernamePipe implements PipeTransform {

  transform(value: string, username: string): string {
    if (!value) return value;
    return value.replace(/\$\{username\}/g, username);
  }

}
