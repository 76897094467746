import {
  Component,
  Input,
  Optional,
  Host,
  SkipSelf,
  OnInit,
} from '@angular/core';
import {
  ControlContainer,
  FormGroupDirective,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
} from '@angular/forms';
import { ERROR_MESSAGES } from '../../constants/text-field';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';

@Component({
  selector: 'app-form-group',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NgxMaskDirective],
  templateUrl: './form-group.component.html',
  styleUrls: ['./form-group.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FormGroupComponent,
    },
    provideNgxMask(),
  ],
})
export class FormGroupComponent implements OnInit, ControlValueAccessor {
  formGroup: FormGroup;
  phoneMask = '000-000-0000';
  zipMask = '00000';
  counterMask = '0*';
  @Input() label!: string;
  @Input() id!: string;
  @Input() formControlName!: string;
  @Input() placeholder: string = '';
  @Input() type?: string = 'text';
  @Input() counter?: number;
  @Input() textarea?: boolean;
  @Input() showIcons?: boolean = true;
  @Input() errorMessages?: { [key: string]: string };
  @Input() inputCentered?: boolean;
  @Input() required?: boolean;

  ERROR_MESSAGES = ERROR_MESSAGES;

  constructor(
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer
  ) {}

  // mock functions for ControlValueAccessor
  writeValue(obj: any): void {}
  registerOnChange(fn: any): void {}
  registerOnTouched(fn: any): void {}

  ngOnInit(): void {
    this.formGroup = this.controlContainer.control as FormGroup;
  }
}
